import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import "./emitirCndGlobal.scss";
import Form, { SimpleItem, GroupItem, Label, EmptyItem, ButtonItem } from "devextreme-react/form";
import api from "../../services/api";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { DataGrid, Column, Paging, Pager } from "devextreme-react/data-grid";

const ConfirmarDadosIptu = ({ location }) => {
  const history = useHistory();
  const { prefeitura, exercicio } = useParams();
  let dados = setDados();
  const [data, setEstado] = useState(dados);


  function setDados() {
    if (location.props !== undefined) {
      return {
        imoveis: location.props.length > 1 ? location.props : [],
        selecionado: location.props.length == 1 ? location.props[0] : undefined
      };
    } else {
      history.push(`/${prefeitura}/imprimir-iptu`);
      return {};
    }
  }

  async function handleParcelaClick(e) {
    window.open(
      `${api.defaults.baseURL
      }pdf/boleto_iptu/${prefeitura}/${exercicio}/${data.selecionado.inscricao
      }/parcelamento`
    );
  }

  async function handleDescontoClick(e) {
    window.open(`${api.defaults.baseURL}pdf/boleto_iptu/${prefeitura}/${exercicio}/${data.selecionado.inscricao}/cotaunica`);
  }


  async function handleParcelaImoveisClick(e) {
    window.open(`${api.defaults.baseURL}pdf/boleto_iptu/${prefeitura}/${exercicio}/${e.row.data.inscricao}/parcelamento`);
  }

  async function handleDescontoImoveisClick(e) {
    try {
      window.open(`${api.defaults.baseURL}pdf/boleto_iptu/${prefeitura}/${exercicio}/${e.row.data.inscricao}/cotaunica`);
    } catch (err) {
      console.log(err);
    }
  }

  function selecionarImovelClick(e) {
    try {
      setEstado({ ...data, selecionado: e.row.data });
    } catch (err) {
      console.log(err);
    }
  }

  if (data.selecionado == undefined) {
    return (
      <React.Fragment>
        <div className={"content-block"}>
          <div className={"my-card responsive-paddings"}>
            <div className="input-box">
              <h2>IMÓVEIS DO DOCUMENTO INFORMADO</h2>
              <br></br>
              <DataGrid
                dataSource={data.imoveis}
                showBorders={true}
                rowAlternationEnabled={true}
                remoteOperations={true}
                columnAutoWidth={true}
                wordWrapEnabled={true}
                sorting={false}
              >
                <Column type="buttons"
                  buttons={[{
                    hint: 'Selecionar',
                    icon: "arrowright",
                    onClick: selecionarImovelClick
                  }, {
                    hint: "Imprimir Cota Única COM DESCONTO",
                    icon: "percent",
                    onClick: handleDescontoImoveisClick
                  }, {
                    hint: "Imprimir Parcelamento",
                    icon: "orderedlist",
                    onClick: handleParcelaImoveisClick
                  }]} />
                <Column dataField={"inscricao"} caption={"Inscrição"} width={100} alignment="center" />
                <Column dataField={"endereco"} caption={"Endereço"} />
                <Column dataField={"valorImposto"} caption={"Valor do IPTU (R$)"} format={{ type: "fixedPoint", precision: 2 }} />
                <Column dataField={"qtdeParcelas"} caption={"Qtde. Parcelas"} />
                <Paging defaultPageSize={20} />
                <Pager showPageSizeSelector={true} />
              </DataGrid>
            </div>
          </div>
        </div>
      </React.Fragment >


    );
  }

  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"my-card responsive-paddings"}>
          <div className="input-box">
            <h2>IPTU {exercicio}</h2>
            <br></br>
            <form>
              <Form formData={data.selecionado} colCount={2} labelLocation={"top"}>
                <GroupItem caption={"INFORMAÇÕES DO IMÓVEL"} colSpan={2} colCount={5}>
                  <SimpleItem
                    colSpan={1}
                    editorOptions={{ readOnly: true }}
                    dataField={"inscricao"}
                  >
                    <Label text="INSCRIÇÃO" />
                  </SimpleItem>
                  <SimpleItem
                    colSpan={2}
                    editorOptions={{ readOnly: true }}
                    dataField={"cartografia"}
                  >
                    <Label text="CARTOGRAFIA" />
                  </SimpleItem>

                  <SimpleItem
                    colSpan={1}
                    editorType={"dxNumberBox"}
                    editorOptions={{
                      readOnly: true,
                      format: { type: "fixedPoint", precision: 2 },
                    }}
                    dataField={"valorImposto"}
                  >
                    <Label text="Valor do IPTU" />
                  </SimpleItem>

                  <SimpleItem
                    colSpan={1}
                    editorType={"dxNumberBox"}
                    editorOptions={{ readOnly: true }}
                    dataField={"qtdeParcelas"}
                  >
                    <Label text="Parcelas" />
                  </SimpleItem>

                  <SimpleItem
                    colSpan={5}
                    editorOptions={{ readOnly: true }}
                    dataField={"endereco"}
                  >
                    <Label text="ENDEREÇO" />
                  </SimpleItem>
                </GroupItem>

                <GroupItem caption={"INFORMAÇÕES DO PROPRIETÁRIO"} colSpan={2} colCount={3}>
                  <SimpleItem
                    colSpan={2}
                    editorOptions={{ readOnly: true }}
                    dataField={"proprietario"}
                  >
                    <Label text="PROPRIETÁRIO" />
                  </SimpleItem>
                  <SimpleItem
                    colSpan={1}
                    editorOptions={{ readOnly: true }}
                    dataField={"cpfCnpj"}
                  >
                    <Label text="CPF/CNPJ" />
                  </SimpleItem>
                </GroupItem>

                <GroupItem caption={"PARCELAS"} colSpan={2} colCount={1}>
                  <DataGrid
                    dataSource={data.selecionado.parcelas}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    remoteOperations={true}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    wordWrapEnabled={window.innerWidth < 800}
                    sorting={false}
                  >
                    <Column dataField={"descricao"} caption={"Parcela"} />

                    <Column
                      alignment={"center"}
                      dataField={"vencimento"}
                      caption={"Vencimento"}
                      dataType="date"
                      width={120}
                    />

                    <Column
                      alignment={"right"}
                      dataField={"principal"}
                      format={{ type: "fixedPoint", precision: 2 }}
                      caption={"Principal"}
                    />
                    <Column
                      alignment={"center"}
                      dataField={"desconto"}
                      format={{ type: "fixedPoint", precision: 2 }}
                      caption={"Desconto"}
                    />
                    <Column
                      alignment={"right"}
                      dataField={"valorAPagar"}
                      format={{ type: "fixedPoint", precision: 2 }}
                      caption={"Valor a Pagar"}
                    />
                    <Paging defaultPageSize={20} />
                    <Pager showPageSizeSelector={true} />
                  </DataGrid>
                </GroupItem>
              </Form>
              <Form screenByWidth={(width) => width < 1036 ? "sm" : "md"} colCountByScreen={{ sm: 1, md: 3 }}>
                <ButtonItem colSpan={2} horizontalAlignment="left" buttonOptions={{
                  onClick: handleDescontoClick, 
                  text: `Imprimir Cota Única com ${data.selecionado.descontoCotaUnica} de desconto`,
                  type: 'success', 
                  useSubmitBehavior: false, 
                  width: "100%", 
                  height: "50px",
                  visible: data.selecionado.descontoCotaUnica != "-"
                }} />
                <ButtonItem horizontalAlignment="left" buttonOptions={{ onClick: handleParcelaClick, text: 'Imprimir Parcelamento', type: 'default', useSubmitBehavior: false, width: "100%", height: "50px" }} />
              </Form>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
};

export default ConfirmarDadosIptu;
