import React from 'react';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import './user-panel.scss';
import { estaLogado, obterNomeUsuario, removerUserData } from "../../services/LoginService";
import {withRouter} from "react-router-dom";

class UserPanel extends React.Component {

  render() {

    let props = this.props;
    const { menuMode, menuItems, loginSource, logOut, codigoPrefeitura } = props;

    let menu = [
      {
        text: 'Sair',
        icon: 'runner',
        onClick: function () {
          removerUserData();
          // props.history.push(`/${codigoPrefeitura}/`);
          logOut();
        }
      }
    ];

    return (
      <div className={'user-panel'}>
        <div className={'user-info'}>
          {/* <div className={'image-container'}>
            <div className={'user-image'}  />
          </div> */}
          <div className={'dx-icon dx-icon-user'} />
          <div className={'user-name'}>{estaLogado() ? obterNomeUsuario() : "Entrar"}</div>
        </div>

        {menuMode === 'context' && (
          <ContextMenu
            items={menu}
            target={'.user-button'}
            showEvent={'dxclick'}
            width={172}
            cssClass={'user-menu'}
          >
            <Position my={'top center'} at={'bottom center'} />
          </ContextMenu>
        )}
        {menuMode === 'list' && (
          <List className={'dx-toolbar-menu-action'} items={menu} />
        )}
      </div>
    );
  }
}

export default withRouter(UserPanel);