import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Form, { SimpleItem, GroupItem, Label } from "devextreme-react/form";
import api from "../../services/apiSgtWeb";
import notify from "devextreme/ui/notify";
import captchaKey from "../../tests/captchakey";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import { LoadIndicator, LoadPanel } from "devextreme-react";
import showReport from "../../showReport";
import "./index.css";

const DamContribuinte = () => {
  const [data, setData] = useState({
    permission: false,
    contribuinteInfos: {},
  });
  const [estagioTela, seEstagioTela] = useState(1);
  const [damTypes, setDamTypes] = useState({});
  const [taxpayerErrors, setTaxpayerErrors] = useState("");
  const [popupVisible, setPopupVisible] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [damParameters, setDamParameters] = useState({
    chaveLancamento: "",
    damId: null,
    prefeituraId: null,
  });
  const { prefeitura } = useParams();

  useEffect(() => {
    const fetchDamTypes = async () => {
      try {
        const response = await api.get(`dam/start_dam_portal/${prefeitura}`);
        if (response.status === 200 && response.data) {
          setDamTypes(response.data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchDamTypes();
  }, [prefeitura]);

  const damOptions = {
    editorType: "dxSelectBox",
    searchEnabled: false,
    dataSource: damTypes,
    valueExpr: "id",
    displayExpr: (item) => {
      return `${item.descricao} - R$ ${item.valor}`;
    },
    onValueChanged: (e) => setData({ ...data, tipoDam: e.value }),
  };

  const personOptions = {
    editorType: "dxSelectBox",
    searchEnabled: false,
    dataSource: [
      { id: 1, descricao: "Pessoa Física" },
      { id: 2, descricao: "Pessoa Jurídica" },
    ],
    valueExpr: "id",
    displayExpr: "descricao",
  };

  function handleReCaptchaSize() {
    let smallSize = window.matchMedia("(max-width: 900px)");
    if (smallSize.matches) {
      return "compact";
    } else {
      return "normal";
    }
  }

  function onChange(e) {
    setData({ ...data, permission: true });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setLoaderVisible(true);

    try {
      const response = await api.post("dam/validar_contribuinte_portal", data);
      if (response.status === 200 && response.data.resultado === "") {
        setData({ ...data, contribuinteInfos: response.data.dto });
        setTaxpayerErrors("");
        seEstagioTela(2);
      } else if (response.status === 200 && response.data.resultado.length > 0) {
        seEstagioTela(1);
        setData({ ...data, contribuinteInfos: {} });
        setTaxpayerErrors(response.data.resultado);
      }

      setLoaderVisible(false);

    } catch (err) {
      let msg = "Os dados do contribuinte são inválidos. Tente novamente.";
      if (err.response && err.response.data) {
        msg = err.response.data;
      }
      notify(
        {
          message: msg,
          closeOnClick: true,
          position: { at: "top center", my: "top center", offset: "0 50" },
        },
        "error",
        5000
      );
      seEstagioTela(1);
    }
  }

  async function generateDamAction() {
    
    if (data.permission !== false) {

      setLoaderVisible(true);
      
      try {
        const response = await api.post("dam/gerar_dam_portal", {
          tipoDamId: data.tipoDam,
          codigoPrefeitura: prefeitura,
          contribuinteInfos: data.contribuinteInfos,
        });
        if (response.status === 200) {
          setLoaderVisible(false);
          seEstagioTela(3);
          setDamParameters(response.data);
        }
      } catch (error) {
        notify(error, "error", 5000);
      }
    } else {
      notify("É necessário responder o ReCAPTCHA", "error");
      
    }
  }

  async function printDam() {

    const { chaveLancamento, damId, prefeituraId } = damParameters;

    setPopupVisible(false);
    await showReport(
      `report/dam2_geral_chave_lancamento/${chaveLancamento}/${prefeituraId}/${damId}`
    );
  }

  if (loaderVisible) {
    return (
      <React.Fragment>

        <div className={"content-block"}>
          <div className={"my-card responsive-paddings"} id="popup-download-dam">
            <div className="input-box">
              <div style={{ textAlign: "center" }}>
                <h5 >Processando solicitação...</h5>
                <p>Aguarde, estamos verificando os dados informados.</p>
                <LoadIndicator />
              </div>
            </div>
          </div>
        </div>

      </React.Fragment>
    );
  }



  if (estagioTela == 1) {
    return (
      <React.Fragment>
        <div className={"content-block"}>
          <div className={"my-card responsive-paddings"} id="popup-download-dam">
            <div className="input-box">
              <h2>TAXA DE INSRIÇÃO</h2>
              <form onSubmit={handleSubmit}>
                <Form formData={data} colCount={1} labelLocation={"top"}>
                  <GroupItem>
                    <SimpleItem
                      dataField={"tipoDam"}
                      editorType={"dxLookup"}
                      editorOptions={damOptions}
                      isRequired={true}
                    >
                      <Label text="Tipo de DAM" />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem colCount={4}>
                    <SimpleItem dataField={"nome"} colSpan={3} isRequired={true}>
                      <Label text="Nome do Contribuinte" />
                    </SimpleItem>
                    <SimpleItem
                      dataField={"cpfCnpj"}
                      colSpan={1}
                      isRequired={true}
                    >
                      <Label text="CPF" />
                    </SimpleItem>
                    <SimpleItem dataField={"email"} colSpan={3} isRequired={true}>
                      <Label text="E-mail" />
                    </SimpleItem>
                    <SimpleItem dataField={"celular"} colSpan={1} isRequired={true}>
                      <Label text="Telefone" />
                    </SimpleItem>
                    {/* <SimpleItem
                        dataField={"tipoPessoa"}
                        colSpan={1}
                        editorType={"dxLookup"}
                        editorOptions={personOptions}
                        isRequired={true}
                      >
                        <Label text="Tipo de Pessoa" />
                      </SimpleItem> */}
                    <SimpleItem dataField={"cep"} colSpan={1} isRequired={true}>
                      <Label text="CEP" />
                    </SimpleItem>
                    <SimpleItem
                      dataField={"logradouro"}
                      colSpan={2}
                      isRequired={true}
                    >
                      <Label text="Logradouro" />
                    </SimpleItem>
                    <SimpleItem
                      dataField={"numero"}
                      colSpan={1}
                      isRequired={true}
                    >
                      <Label text="Nº" />
                    </SimpleItem>
                    <SimpleItem dataField={"complemento"} colSpan={1}>
                      <Label text="Complemento" />
                    </SimpleItem>
                    <SimpleItem dataField={"bairro"} isRequired={true}>
                      <Label text="Bairro" />
                    </SimpleItem>
                    <SimpleItem
                      dataField={"cidade"}
                      colSpan={1}
                      isRequired={true}
                    >
                      <Label text="Cidade" />
                    </SimpleItem>
                    <SimpleItem dataField={"uf"} isRequired={true}>
                      <Label text="UF" />
                    </SimpleItem>
                  </GroupItem>
                </Form>
                <div style={{ alignContent: "center", textAlign: "center" }}>
                  <button
                    className="button"
                    type="submit"
                    style={{ width: 250 }}
                  >
                    Prosseguir
                  </button>
                </div>

              </form>
              <div
                className="taxpayer-errors"
                style={{
                  display: taxpayerErrors.length === 0 ? "none" : "",
                }}
              >
                <h3>Erros</h3>
                <ul>
                  {taxpayerErrors.split(";").map((item, key) => {
                    if (item.length > 0) {
                      return (
                        <li id={key} key={key}>
                          {item}
                        </li>
                      );
                    }
                    return (<></>);
                  })}
                </ul>
              </div>

              <p>( * ) Obrigatório</p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  if (estagioTela == 2) {
    return (
      <React.Fragment>

        <div className={"content-block"}>
          <div className={"my-card responsive-paddings"} id="popup-download-dam">
            <div className="input-box">
              <div style={{ textAlign: "center" }}>
                <h5 style={{ color: "green" }}>Dados válidos!</h5>
                <p>Dados verificados com sucesso, pressione o botão abaixo para gerar o DAM.</p>
              </div>
              <ReCAPTCHA
                className="captcha"
                hl="pt-BR"
                sitekey={captchaKey.key}
                onChange={onChange}
                size={handleReCaptchaSize()}
                onExpired={() => setData({ ...data, permission: false })}
              />
              <div className="popup-download-dam">
                <button onClick={generateDamAction} className="button" style={{ width: 250 }}>
                  Gerar DAM
                </button>
              </div>
            </div>

          </div>
        </div>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: "#popup-download-dam" }}
          onHiding={() => setLoaderVisible(false)}
          visible={loaderVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          hideOnOutsideClick={false}
        />
      </React.Fragment>
    );
  }

  if (estagioTela == 3) {
    return (
      <React.Fragment>

        <div className={"content-block"}>
          <div className={"my-card responsive-paddings"} id="popup-download-dam">
            <div className="input-box">
              <div style={{ textAlign: "center" }}>
                <h5 style={{ color: "green" }}>Requisição bem sucedida!</h5>
                <p>O DAM solicitado foi gerado com sucesso, você pode imprimí-lo pressionando o botão abaixo.</p>
              </div>
              <div className="popup-download-dam">
                <button onClick={printDam} className="button" style={{ width: 250 }}>
                  Imprimir DAM
                </button>
              </div>
            </div>

          </div>
        </div>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: "#popup-download-dam" }}
          onHiding={() => setLoaderVisible(false)}
          visible={loaderVisible}
          showIndicator={true}
          shading={true}
          showPane={true}
          hideOnOutsideClick={false}
        />
      </React.Fragment>
    );
  }

  if (estagioTela == 3) {
    return (
      <React.Fragment>
        <div className={"content-block"}>
          <div className={"my-card responsive-paddings"} id="popup-download-dam">
            <div className="input-box">
              <h2>DAM Contribuinte</h2>
              <form onSubmit={handleSubmit}>
                <Form formData={data} colCount={1} labelLocation={"top"}>
                  <GroupItem>
                    <SimpleItem
                      dataField={"tipoDam"}
                      editorType={"dxLookup"}
                      editorOptions={damOptions}
                    >
                      <Label text="Tipo de DAM" />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem colCount={4}>
                    <SimpleItem dataField={"nome"} colSpan={3} isRequired={true}>
                      <Label text="Nome do Contribuinte" />
                    </SimpleItem>
                    <SimpleItem
                      dataField={"cpfCnpj"}
                      colSpan={1}
                      isRequired={true}
                    >
                      <Label text="CPF" />
                    </SimpleItem>
                    <SimpleItem dataField={"email"} colSpan={3}>
                      <Label text="E-mail" />
                    </SimpleItem>
                    <SimpleItem dataField={"celular"} colSpan={1}>
                      <Label text="Telefone" />
                    </SimpleItem>
                    {/* <SimpleItem
                        dataField={"tipoPessoa"}
                        colSpan={1}
                        editorType={"dxLookup"}
                        editorOptions={personOptions}
                        isRequired={true}
                      >
                        <Label text="Tipo de Pessoa" />
                      </SimpleItem> */}
                    <SimpleItem dataField={"cep"} colSpan={1} isRequired={true}>
                      <Label text="CEP" />
                    </SimpleItem>
                    <SimpleItem
                      dataField={"logradouro"}
                      colSpan={2}
                      isRequired={true}
                    >
                      <Label text="Logradouro" />
                    </SimpleItem>
                    <SimpleItem
                      dataField={"numero"}
                      colSpan={1}
                      isRequired={true}
                    >
                      <Label text="Nº" />
                    </SimpleItem>
                    <SimpleItem dataField={"complemento"} colSpan={1}>
                      <Label text="Complemento" />
                    </SimpleItem>
                    <SimpleItem dataField={"bairro"} isRequired={true}>
                      <Label text="Bairro" />
                    </SimpleItem>
                    <SimpleItem
                      dataField={"cidade"}
                      colSpan={1}
                      isRequired={true}
                    >
                      <Label text="Cidade" />
                    </SimpleItem>
                    <SimpleItem dataField={"uf"} isRequired={true}>
                      <Label text="UF" />
                    </SimpleItem>
                  </GroupItem>
                </Form>
                <div style={{ alignContent: "center", textAlign: "center" }}>
                  <button
                    className="button"
                    type="submit"
                    style={{ width: 250 }}
                  >
                    Prosseguir
                  </button>
                </div>

              </form>
              <div
                style={{
                  display:
                    Object.keys(data.contribuinteInfos).length === 0
                      ? "none"
                      : "",
                }}
              >

                <div style={{ alignContent: "center", textAlign: "center" }}>
                  <button
                    className="button"
                    onClick={generateDamAction}
                    disabled={loaderVisible ? true : false}
                    style={{ width: 250 }}
                  >
                    Gerar DAM
                  </button>
                </div>

              </div>
              <div
                className="taxpayer-errors"
                style={{
                  display: taxpayerErrors.length === 0 ? "none" : "",
                }}
              >
                <h3>Erros</h3>
                <ul>
                  {taxpayerErrors.split(";").map((item, key) => {
                    if (item.length > 0) {
                      return (
                        <li id={key} key={key}>
                          {item}
                        </li>
                      );
                    }
                    return (<></>);
                  })}
                </ul>
              </div>

              <p>( * ) Obrigatório</p>
            </div>
          </div>
          <Popup
            visible={popupVisible}
            width={250}
            height={150}
            showCloseButton={false}
            disabled={loaderVisible}
            dragEnabled={false}
            showTitle={false}
          >
            <div>
              <button onClick={printDam} className="button">
                Imprimir DAM
              </button>
            </div>
            <ToolbarItem
              toolbar={"bottom"}
              widget="dxButton"
              location="after"
              options={{
                text: "Fechar",
                width: 150,
                onClick: () => setPopupVisible(false),
              }}
            />
          </Popup>
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={{ of: "#popup-download-dam" }}
            onHiding={() => setLoaderVisible(false)}
            visible={loaderVisible}
            showIndicator={true}
            shading={true}
            showPane={true}
            hideOnOutsideClick={false}
          />
        </div>
      </React.Fragment>
    );
  }


};

export default DamContribuinte;
