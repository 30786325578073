import React from "react";
import { withRouter } from "react-router";
//import { SingleCard } from "../../layouts";

import LoginForm from "./login-form";
import { estaLogado } from "../../services/LoginService";
import { Redirect } from "react-router-dom";

const LoginPage = (props) => {

  if (estaLogado()) {
    return <Redirect to={`/${props.match.params.prefeitura}/`} />;
  }

  return (
    <div className={"content-block"}>
      <div className={"my-card responsive-paddings"}>
        <div className="input-box" style={{ maxWidth: "500px" }}>
          <LoginForm {...props} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(LoginPage);
