import React, { useState } from "react";
import "./index.scss";
import { useParams, useHistory } from "react-router-dom";
import api from '../../services/api';
import { FaBarcode, FaFileDownload } from "react-icons/fa";
// import DataGrid, { Column, Pager, Paging } from "devextreme-react/data-grid";
import showReport from "../../showReport";
import notify from "devextreme/ui/notify";

const Acompanhamento = ({ location }) => {

  const urlDev = 'http://localhost:4040/api';
  const urlTestes = "http://45.35.104.141:4040/api";
  const urlProd = "http://45.32.167.221:4040/api";
  
  let url = "";
  
  if (process.env.REACT_APP_ESTAGIO === "dev") { url = urlDev; }
  else if (process.env.REACT_APP_ESTAGIO === "testes") { url = urlTestes; }
  else if (process.env.REACT_APP_ESTAGIO === "prod") { url = urlProd; }

  let dados = setDados();
  const [data] = useState(dados);
  const { prefeitura } = useParams();
  const history = useHistory();

  function setDados() {
    if (location.props !== undefined) {
      return location.props;
    } else {
      return {
        contribuinte: null,
        statusStr: "",
        statusPagtoStr: "",
        tipoStr:"",
        eventos: [],
        certidaoEmitida: false,
        damEmitido:false
      };
    }
  }

  function showContribuinteData() {
    if (data.contribuinte !== null) {
      return (
        <React.Fragment>
          <h5>Nome: {data.contribuinte.nome}</h5>
          <h5>Documento: {data.contribuinte.cpfCnpj}</h5>
        </React.Fragment>
      );
    } else {
      history.replace(`/${prefeitura}/buscar-protocolo/`);
    }
  }

  return (
    <React.Fragment>
      <div className={"content-block dx-card responsive-paddings"}>
        <div className="card-content">
          <div className="protocol-content">
            <div className="dados">
              {showContribuinteData()}
              <h5>
                Status: {data.tipoStr.charAt(0)}
                {data.tipoStr.slice(1).toLowerCase()}
              </h5>
              <h5>
                Status: {data.statusStr.charAt(0)}
                {data.statusStr.slice(1).toLowerCase()}
              </h5>
              <h5>
                Status do Pagamento: {data.statusPagtoStr.charAt(0)}
                {data.statusPagtoStr.slice(1).toLowerCase()}{" "}
              </h5>
              {data.certidaoEmitida ? <button
                  className={"cnd-button"}
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("dados acompanhamento", data);
                    
                    if (data.origem == 0) {
                      window.open(
                        `${url}/pdf/cnd/${prefeitura}/${data.protocolo}`
                      );
                    }
                    else {
                      const cndId = data.cndId ? data.cndId : data.protocolo;
                      showReport(`/report/cnd/${cndId}/${data.prefeituraId}`);
                    }
                    
                  }}
                > <FaFileDownload size={30} style={{marginRight:'10px'}} /> Visualizar Certidão</button> : null}
              {data.damEmitido ?  <button
                  className={"cnd-button"}
                  onClick={(e) => {
                    e.preventDefault();
                    if (data.origem == 0) {
                      window.open(
                        `${url}/pdf/dam/${prefeitura}/${data.protocolo}`
                      );
                    }
                    else {
                      notify("Solicite a sua Prefeitura verificar junto a TRIMAP a emissão de DAM de cnd.");
                    }
                    
                  }}
                ><FaBarcode size={30} style={{marginRight:'10px'}} /> Visualizar Boleto</button> : null}
             
            </div>
            <br></br>
            {/* <div className="events-table">
              <DataGrid dataSource={data.eventos} showBorders={true}>
                <Paging defaultPageSize={10} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[5, 10, 20]}
                  showInfo={true}
                />

                <Column dataField="dataEHora" caption={"Data e Hora"} />
                <Column dataField="evento" caption={"Evento"} />
              </DataGrid>
            </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Acompanhamento;
