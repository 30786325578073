import React, { Component } from "react";
import { useParams, Link } from "react-router-dom";
import "./home.scss";
import { FaFileAlt, FaSearch, FaCheck } from "react-icons/fa";
import api from "./../../services/api";
import { obterUserInfo } from "../../services/LoginService";


export default class Home extends Component {

  constructor(props) {
    super(props);
    this.state = { objPrefeitura: {} };
  }

  async componentDidMount() {
    let resp = await api.get(`/prefeitura/${this.props.match.params.prefeitura}`);
    this.setState({ objPrefeitura: resp.data });

  }

  render() {
    const objPrefeitura = this.state.objPrefeitura;
    const userInfo = obterUserInfo();

    if (userInfo == undefined || userInfo.codigoCartorio == 0) {
      return (
        <React.Fragment>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings home-container"}>
              <h3>Serviços Tributários</h3>
              <div className="card-menu-items" >
                <Link to={"cnd/imovel/"} className="item-box" style={{ display: objPrefeitura.emitirCnd ? null : "none" }}>
                  <FaFileAlt size={50} /> Emitir Certidão Negativa de Débitos do
                  Imóvel
                </Link>
                <Link to={"cnd/economico/"} className="item-box" style={{ display: objPrefeitura.emitirCnd ? null : "none" }}>
                  <FaFileAlt size={50} /> Emitir Certidão Negativa de Débitos
                  Econômico
                </Link>
                <Link to={"cnd/contribuinte/"} className="item-box" style={{ display: objPrefeitura.emitirCnd ? null : "none" }}>
                  <FaFileAlt size={50} /> Emitir Certidão Negativa de Débitos do
                  Contribuinte
                </Link>
                <Link to={"acompanhamento/"} className="item-box" style={{ display: objPrefeitura.acompanharProtocolo ? null : "none" }}>
                  <FaSearch size={36} /> Acompanhamento de Protocolo
                </Link>
                <Link to={"validar-cnd/"} className="item-box" style={{ display: objPrefeitura.validarCnd ? null : "none" }}>
                  <FaCheck size={36} style={{ marginRight: "36px" }} /> Validar Certidão
                </Link>
                <Link to={`imprimir-iptu/${objPrefeitura.anoImprimirIptu}`} className="item-box" style={{ display: objPrefeitura.anoImprimirIptu != 0 && objPrefeitura.anoImprimirIptu != -1 ? null : "none" }}>
                  <FaFileAlt size={50} /> Imprimir IPTU {objPrefeitura.anoImprimirIptu}
                </Link>
                <Link to={`imprimir-iptu/${objPrefeitura.anoImprimirIptu2}`} className="item-box" style={{ display: objPrefeitura.anoImprimirIptu2 != 0 && objPrefeitura.anoImprimirIptu2 != -1 ? null : "none" }}>
                  <FaFileAlt size={50} /> Imprimir IPTU {objPrefeitura.anoImprimirIptu2}
                </Link>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }

    if (userInfo && userInfo.codigoCartorio != 0) {
      return (
        <React.Fragment>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings home-container"}>
              <h3>Serviços</h3>
              <div className="card-menu-items" >
                <Link to={"cda"} className="item-box">
                  <FaFileAlt size={50} /> CDA Protesto
                </Link>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }


  }
};

