import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../App.css";
import api from "../../services/api";
import { useHistory } from "react-router-dom";
import "./notfoundpage.scss";
import Logo from "../../assets/trimap-logo.jpg";
import ambiente from "../../services/ambiente";

const NotFoundPage = () => {
  const [prefeituras, setPrefeituras] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const source = axios.CancelToken.source();

    api
      .get("/prefeitura", {
        cancelToken: source.token,
      })
      .then((response) => {
        if (response.status === 200 && response.data !== undefined) {
          setPrefeituras(response.data);
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
        } else {
          throw err;
        }
      });
    return () => {
      source.cancel();
    };
  }, []);

  function pushPage(prefeituraPath = "") {
    if (prefeituraPath) {
      const prefeituraData = prefeituraPath.split(",");
      localStorage.setItem("municipioId", prefeituraData[1]);
      history.replace(`/${prefeituraData[0]}/`);
      window.location.reload();
    }
  }

  return (
    <React.Fragment>
      <div className={"content-block dx-card responsive-paddings notfound"}>
        <div className="card-content">
          <img src={Logo} alt="TRIMAP" className="logo" />
          {ambiente.producao ? null : (
            <h3>{"Ambiente " + ambiente.nomeAmbiente}</h3>
          )}
          <h3>Prefeitura não encontrada</h3>
          Não foi possível localizar uma prefeitura nesse endereço. Abaixo segue
          a lista de nossas prefeituras:
          <select
            className={"select-box"}
            placeholder="Escolha uma Prefeitura"
            onChange={({ target }) => pushPage(target.value)}
          >
            <option value="">Selecione uma prefeitura</option>
            {prefeituras.length > 0
              ? prefeituras.map((x) => {
                return (
                  <option
                    key={x.codigoPortal}
                    value={[x.codigoPortal, x.codigoIbge]}
                  >
                    {x.prefeitura}
                  </option>
                );
              })
              : null}
          </select>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NotFoundPage;
