import notify from "devextreme/ui/notify";
import api from "./services/api";
import ambiente from "./services/ambiente";
import { obterUserInfo } from "./services/LoginService";

const navigation = async (prefeitura, estaLogado, userInfo) => {
  let resp;

  try {
    resp = await api.get(`/prefeitura/${prefeitura}`);
  } catch (error) {
    console.log(error);
    notify(error, "error");
    return [];
  }

  const objPrefeitura = resp.data;

  let codigoIbge = objPrefeitura.codigoIbge;

  return [
    {
      text: "Acesso NFS-e",
      path: `${ambiente.URL_NFSE}?municipio=${codigoIbge}`,
      icon: "copy",
      externo: true,
      visible: objPrefeitura.acessoNf && (userInfo == undefined || userInfo.codigoCartorio == 0),
    },
    {
      text: "Validar NF",
      path: `${ambiente.URL_VALIDADOR}`,
      icon: "copy",
      externo: true,
      visible: objPrefeitura.validador && (userInfo == undefined || userInfo.codigoCartorio == 0),
    },
    {
      text: "Serviços",
      path: `/${prefeitura}/`,
      icon: "mediumiconslayout",
      visible: userInfo == undefined && (userInfo == undefined || userInfo.codigoCartorio == 0)
    },
    // {
    //   text: 'Profile',
    //   path: `/${prefeitura}/profile/`,
    //   icon: 'home'
    // },
    // {
    //   text: 'Display Data',
    //   path: `/${prefeitura}/display-data/`,
    //   icon: 'home'
    // },
    {
      text: "Emitir CND",
      visible: objPrefeitura.emitirCnd && (userInfo == undefined || userInfo.codigoCartorio == 0),
      icon: "folder",
      items: [
        {
          text: "Imóvel",
          path: `/${prefeitura}/cnd/imovel/`,
        },
        {
          text: "Empresa",
          path: `/${prefeitura}/cnd/economico/`,
        },
        {
          text: "Contribuinte",
          path: `/${prefeitura}/cnd/contribuinte/`,
        },
      ],
    },
    {
      text: "Acompanhar Protocolo",
      path: `/${prefeitura}/buscar-protocolo/`,
      icon: "search",
      visible: objPrefeitura.acompanharProtocolo && (userInfo == undefined || userInfo.codigoCartorio == 0),
    },
    {
      text: "CDA Protesto",
      path: `/${prefeitura}/cda/`,
      icon: "doc",
      visible: userInfo && userInfo.codigoCartorio != 0,
    },
    {
      text: "Validar Certidão",
      path: `/${prefeitura}/validar-cnd/`,
      icon: "check",
      visible: objPrefeitura.validarCnd && (userInfo == undefined || userInfo.codigoCartorio == 0),
    },
    {
      text: "Validar Documentos",
      path: `/${prefeitura}/validar-documentos/`,
      icon: "check",
      visible: userInfo == undefined || userInfo.codigoCartorio == 0,
    },
    {
      text: "Protocolo",
      path: `/${prefeitura}/protocolo/`,
      icon: "exportselected",
      visible: false,
    },
    {
      text: "Buscar Protocolo",
      path: `/${prefeitura}/acompanhar-protocolo/`,
      icon: "search",
      visible: false,
    },
    {
      text: "Alvarás Sanitários",
      path: `/${prefeitura}/alvara-sanitario/`,
      icon: "doc",
      visible: estaLogado && obterUserInfo().perfil === "vigilancia_sanitaria",
    },
    {
      text: "Bloquear CND",
      path: `/${prefeitura}/bloquear-cnd/`,
      icon: "check",
      visible: estaLogado && objPrefeitura.bloquearCnd,
      bloquearCnd: objPrefeitura.codigoPortal === "ipaporanga.ce",
    },
    {
      text: `Imprimir IPTU ${objPrefeitura.anoImprimirIptu}`,
      path: `/${prefeitura}/imprimir-iptu/${objPrefeitura.anoImprimirIptu}`,
      icon: "doc",
      visible:
        objPrefeitura.anoImprimirIptu !== 0 &&
        objPrefeitura.anoImprimirIptu !== -1 &&
        (userInfo == undefined || userInfo.codigoCartorio == 0)
    },
    {
      text: `Imprimir IPTU ${objPrefeitura.anoImprimirIptu2}`,
      path: `/${prefeitura}/imprimir-iptu/${objPrefeitura.anoImprimirIptu2}`,
      icon: "doc",
      visible:
        objPrefeitura.anoImprimirIptu2 !== 0 &&
        objPrefeitura.anoImprimirIptu2 !== -1 && 
        (userInfo == undefined || userInfo.codigoCartorio == 0),
    },
    {
      text: `Imprimir Ficha do CPBS`,
      path: `/${prefeitura}/imprimir-ficha-cpbs`,
      icon: "doc",
      visible: false
    },
    {
      text: "Lista de CND",
      path: `/${prefeitura}/cnds`,
      icon: "doc",
      visible: objPrefeitura.listarCnd !== 0 && estaLogado && (userInfo == undefined || userInfo.codigoCartorio == 0),
    },
    {
      text: "Taxa de Inscrição",
      path: `/${prefeitura}/dam-contribuinte`,
      icon: "doc",
      visible: false,
    },
  ];
};

let _configureMenuCallBack;

function configureMenuItens(callback) {
  _configureMenuCallBack = callback;
}

function setMenuItens(items) {
  if (_configureMenuCallBack !== undefined) {
    _configureMenuCallBack(items);
  }
}

export { navigation, configureMenuItens, setMenuItens };
