import React from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/user-panel';
import './header.scss';
import { Template } from 'devextreme-react/core/template';
import { useHistory } from "react-router-dom"
import { estaLogado } from "../../services/LoginService";



const Header = ({ menuToggleEnabled, title, toggleMenu, userMenuItems, loginSource, codigoPrefeitura, logIn, logOut }) => {


  let path = `/${codigoPrefeitura}/login`;
  let history = useHistory();

    return (

    <header className={'header-component'}>
      <Toolbar className={'header-toolbar'}>
        <Item
          visible={menuToggleEnabled}
          location={'before'}
          widget={'dxButton'}
          cssClass={'menu-button'}
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item
          location={'before'}
          cssClass={'header-title'}
          text={title}
          visible={!!title}
        />

        {
          (function () {

            if (estaLogado()) {
              return (
                <Item
                  location={'after'}
                  locateInMenu={'auto'}
                  menuItemTemplate={'userPanelTemplate'}
                >
                  <Button
                    className={'user-button authorization'}
                    width={172}
                    height={'100%'}
                    stylingMode={'text'}
                  >
                    <UserPanel menuItems={userMenuItems} menuMode={'context'} loginSource={loginSource} logOut={logOut} codigoPrefeitura={codigoPrefeitura} />
                  </Button>
                </Item>
              );
            }
            else {
              return (
                <Item
                  visible={menuToggleEnabled}
                  location={'after'}
                  widget={'dxButton'}
                >
                  <Button
                    className={'user-button authorization'}
                    width={172}
                    height={'100%'}
                    stylingMode={'text'}
                    onClick={() => history.push(`/${codigoPrefeitura}/login`)}
                  >
                    <div className={'user-info'}>
                      <div className={'dx-icon dx-icon-user'} />
                      <div className={'user-name'}>Acesso Restrito</div>
                    </div>
                  </Button>
                </Item>
              );
            }
          })()
        }
        {<Template name={'userPanelTemplate'}>
          <UserPanel menuItems={userMenuItems} menuMode={'list'} loginSource={loginSource} />
        </Template>}
      </Toolbar>
    </header >
  );
}

export default Header;