import React, { useState } from "react";
import { useParams, useHistory } from 'react-router-dom'
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import "./index.scss";

const NotAuthPage = ({ location }) => {
  let dados = setDados();
  const [motivos] = useState(dados);
  const { prefeitura } = useParams();
  const history = useHistory();

  function setDados() {
    if (location.props !== undefined) {
      return location.props.motivos;
    } else {
      return [];
    }
  }

  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div
          className={"dx-card responsive-paddings"}
          style={{ minHeight: "78vh" }}
        >
          <h3 style={{fontSize:'20px'}}>Sua solicitação não foi aceita pelos seguintes motivos:</h3>
          <ul>
            {motivos.length > 0
              ? motivos.map((motivo) => {
                  return <li>{motivo}</li>;
                })
              : []}
          </ul>
          <p>Procure o setor de tributos para regularizar sua situação ou caso as situações mencionadas sejam incorretas para verificação e correção dos registros.</p>
          <button
                  className={"button"}
                  style={{ color: "white", backgroundColor: "grey" }}
                  onClick={() => {
                    history.replace(`/${prefeitura}/`);
                  }}
                >
                  <FaRegArrowAltCircleLeft /> Voltar para Serviços
                </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NotAuthPage;
