import React, { useState } from "react";
import "./index.scss";
import { useParams, useHistory } from "react-router-dom";
import { FaFileDownload, FaRegArrowAltCircleLeft } from "react-icons/fa";
// import DataGrid, { Column, Pager, Paging } from "devextreme-react/data-grid";
import showReport from "../../showReport";

const ConfirmarValidacao = ({ location }) => {
  let dados = setDados();
  const [data] = useState(dados);
  const { prefeitura } = useParams();
  const history = useHistory();
  console.log(data);

  function setDados() {
    if (location.props !== undefined) {
      return location.props;
    } else {
      return {
        contribuinte: "",
        status: "",
        documento: "",
        inscricaoEconomico: undefined,
        inscricaoImovel: undefined,
        inscricaoContribuinte: undefined,
        podeVisualizar: false,
        vazio: true,
      };
    }
  }

  function exibeDados() {
    if (!data.vazio) {
      return (
        <div>
          <h5>
            Contribuinte: {data.contribuinte}
          </h5>
          <h5>
            Status: {data.status}
          </h5>
          <h5>
            Documento: {data.documento}
          </h5>
          <h5>
            Inscrição Economico: {data.inscricaoEconomico}
          </h5>
          <h5>
            Inscricao do Imóvel: {data.inscricaoImovel}
          </h5>
          <h5>
            Inscricao do Contribuinte: {data.inscricaoContribuinte}
          </h5>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <div className={"content-block dx-card responsive-paddings"}>
        <div className="card-content">
          <div className="protocol-content">
            <div className="dados">
              {exibeDados()}
              {data.podeVisualizar ? (
                <button
                  className={"cnd-button"}
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("dados acompanhamento", data);
                    if (data.origem == 0) {
                      window.open(
                        `https://apiportal.devtrimap.com.br/api/pdf/cnd/${prefeitura}/${data.protocolo}`
                      );
                    }
                    else {
                      showReport(`/report/cnd/${data.cndId}/${data.prefeituraId}`);
                    }

                  }}
                >
                  {" "}
                  <FaFileDownload
                    size={30}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Visualizar Certidão
                </button>
              ) : null}
              <button
                className={"button"}
                style={{ color: "white", backgroundColor: "grey" }}
                onClick={() => {
                  history.replace(`/${prefeitura}/validar-cnd/`);
                }}
              >
                <FaRegArrowAltCircleLeft /> Voltar
              </button>
            </div>
            <br></br>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ConfirmarValidacao;
