import React, { useState, useEffect } from "react";
import "./index.scss";
import apiSgtWeb from "../../services/apiSgtWeb";
import { FaCheck } from "react-icons/fa";
import { useParams, useHistory } from "react-router-dom";
import notify from "devextreme/ui/notify";
import ReCAPTCHA from "react-google-recaptcha";
import captchaKey from "../../tests/captchakey";

const ValidarDocumentos = () => {
  const [state, setState] = useState({
    campo1: null,
    campo2: null,
    campo3: null,
  });
  const [tipoSelecionado, setTipoSelecionado] = useState({});
  const [validationTypes, setValidationTypes] = useState([]);
  const [captcha, setCaptcha] = useState({
    permission: false,
  });
  
  const history = useHistory();
  
  const { prefeitura } = useParams();

  useEffect(() => {
    const fetchTiposModelo = async () => {
      try {
        const response = await apiSgtWeb.get(
          `validacao_documentos/portal/tipos_prefeitura/${prefeitura}`
        );
        if (response.status === 200 && response.data) {
          setValidationTypes(response.data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchTiposModelo();
  }, [prefeitura]);

  function handleReCaptchaSize() {
    let smallSize = window.matchMedia("(max-width: 900px)");
    if (smallSize.matches) {
      return "compact";
    } else {
      return "normal";
    }
  }

  function onChange(e) {
    setCaptcha({ ...captcha, permission: true });
  }

  const renderFields = (tipoSelecionado) => {
    const tipo = parseInt(tipoSelecionado);
    const inputs = [];
    let tipoData = validationTypes.filter((item) => item.id === tipo);

    if (tipo && tipoData.length !== 0) {
      tipoData = tipoData[0];

      if (tipoData) {
        for (let i = 1; i <= tipoData.qtdeCampos; i++) {
          inputs.push(
            <>
              <input
                key={`inputField${i}`}
                id={`inputField${i}`}
                placeholder={`${tipoData[`legendaCampo${i}`]}`}
                className="card-input"
                type="text"
                value={state[`campo${i}`] ?? ""}
                onChange={({ target }) => {
                  let dummyObject = {};
                  dummyObject[`campo${i}`] = target.value;
                  setState({
                    ...state,
                    ...dummyObject,
                    modeloId: tipoData.modeloId,
                    prefeituraId: tipoData.prefeituraId,
                  });
                }}
              />
            </>
          );
        }
        return inputs;
      }
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (captcha.permission) {
      try {
        const response = await apiSgtWeb.post(
          `validacao_documentos/validar_documento`,
          state
        );
        if (response.status === 200) {
          history.push({
            pathname: `/${prefeitura}/validar-documentos/resultado`,
            props: response.data,
          });
        } else {
          notify(
            {
              message: "Documento inválido. Verifique-o e tente novamente!",
              closeOnClick: true,
              position: { at: "top center", my: "top center", offset: "0 50" },
            },
            "error",
            10000
          );
        }
      } catch (err) {
        notify(
          {
            message:
              "Código de Validação Inválido. Verifique-o e tente novamente!",
            closeOnClick: true,
            position: { at: "top center", my: "top center", offset: "0 50" },
          },
          "error",
          10000
        );
      }
    } else {
      alert("É necessário responder ao ReCaptcha!");
    }
  }

  return (
    <React.Fragment>
      <div className={"content-block dx-card responsive-paddings"}>
        <div className="card-content">
          <form onSubmit={(event) => handleSubmit(event)}>
            <div className="input-group">
              <select
                className={"card-input"}
                placeholder="Escolha um Documento"
                onChange={({ target }) => {
                  setTipoSelecionado(target.value);
                  setState({ campo1: null, campo2: null, campo3: null });
                }}
              >
                <option value="">Selecione um tipo de documento</option>
                {validationTypes.length > 0
                  ? validationTypes.map((tipo) => {
                      return (
                        <option key={tipo.id} value={tipo.id}>
                          {tipo.titulo}
                        </option>
                      );
                    })
                  : null}
              </select>
              {renderFields(tipoSelecionado)}
              <button type="submit" className="button">
                <FaCheck /> Validar Documento
              </button>
            </div>
            <div>
              <ReCAPTCHA
                className="captcha"
                hl="pt-BR"
                sitekey={captchaKey.key}
                onChange={onChange}
                onExpired={() =>
                  setCaptcha({
                    ...captcha,
                    permission: false,
                  })
                }
                size={handleReCaptchaSize()}
              />
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ValidarDocumentos;
