
export const TOKEN_KEY = "@portal-contribuinte-trimap-Token";

export const estaLogado = () => localStorage.getItem(TOKEN_KEY) !== null;

export const obterUserInfo = () => {
    var item = localStorage.getItem(TOKEN_KEY);
    if (item !== undefined) {
        return JSON.parse(item);
    }
}

export const obterNomeUsuario = () => {
    var userInfo = obterUserInfo();
    if (userInfo === undefined) {
        return "";
    }
    return userInfo.login;
}

export const salvarUserData = userInfo => {
    localStorage.setItem(TOKEN_KEY, JSON.stringify(userInfo));
};
export const removerUserData = () => {
    localStorage.removeItem(TOKEN_KEY);
};