import React from "react";
import {
  HomePage,
  DisplayDataPage,
  ProfilePage,
  EmitirCndImovel,
  EmitirCndContribuinte,
  EmitirCndEmpresa,
  ConfirmarDadosImovel,
  ConfirmarDadosEmpresa,
  ConfirmarDadosContribuinte,
  Acompanhamento,
  NotAuthPage,
  BuscarProtocolo,
  ValidarCnd,
  ConfirmarValidacao,
  BloquearCnd,
  ImprimirIptu,
  ConfirmarDadosIptu,
  CndBox,
  ValidarDocumentos,
  Protocolo,
  CdaBox,
  ResultadoValidacao,
  DamContribuinte,
  ImprimirFichaCpbs,
} from "./pages";
import { Route, Redirect } from "react-router-dom";
import LoginPage from "./components/login-form/login-page";
import { estaLogado, obterUserInfo } from "./services/LoginService";
import ProtocoloRecebido from "./pages/protocolo/protocoloRecebido";
import AcompanharProtocolo from "./pages/acompanhamentoProtocolo";
import AlvarasBox from "./pages/alvaraVigilanciaSanitaria/alvarasVigilanciaSanitaria";

const PrivateRoute = (props) => {
  return estaLogado() ? (
    <Route exact={props.exact} path={props.path} component={props.component} />
  ) : (
    <Route exact={true} path={props.path} component={RedirectComp} />
  );
};

const RedirectComp = (props) => {
  return <Redirect to={`/${props.match.params.prefeitura}/`} />;
};

const MyRoutes = (props) => {
  const estaLogadoValue = estaLogado();

  return (
    <div>
      <Route exact={true} path="/display-data" component={DisplayDataPage} />
      <Route exact={true} path="/profile" component={ProfilePage} />
      <Route
        exact={true}
        path="/:prefeitura/cnd/imovel"
        component={EmitirCndImovel}
      />
      <Route
        exact={true}
        path="/:prefeitura/cnd/imovel/confirmar/"
        component={ConfirmarDadosImovel}
      />
      <Route
        exact={true}
        path="/:prefeitura/cnd/economico"
        component={EmitirCndEmpresa}
      />
      <Route
        exact={true}
        path="/:prefeitura/cnd/economico/confirmar/"
        component={ConfirmarDadosEmpresa}
      />
      <Route
        exact={true}
        path="/:prefeitura/cnd/contribuinte"
        component={EmitirCndContribuinte}
      />
      <Route
        exact={true}
        path="/:prefeitura/cnd/contribuinte/confirmar/"
        component={ConfirmarDadosContribuinte}
      />
      <Route
        exact={true}
        path="/:prefeitura/acompanhamento/"
        component={Acompanhamento}
      />
      <Route
        exact={true}
        path="/:prefeitura/buscar-protocolo/"
        component={BuscarProtocolo}
      />
      <Route
        exact={true}
        path="/:prefeitura/validar-cnd/"
        component={ValidarCnd}
      />
      <Route
        exact={true}
        path="/:prefeitura/dam-contribuinte/"
        component={DamContribuinte}
      />
      <Route
        exact={true}
        path="/:prefeitura/validar-documentos/"
        component={ValidarDocumentos}
      />
      <Route
        exact={true}
        path="/:prefeitura/validar-documentos/resultado"
        component={ResultadoValidacao}
      />
      <Route
        exact={true}
        path="/:prefeitura/protocolo/"
        component={Protocolo}
      />
      <Route
        exact={true}
        path="/:prefeitura/protocolo/confirmado/:id"
        component={ProtocoloRecebido}
      />
      <Route
        exact={true}
        path="/:prefeitura/acompanhar-protocolo/"
        component={AcompanharProtocolo}
      />
      <Route
        exact={true}
        path="/:prefeitura/acompanhar-protocolo/:id"
        component={ProtocoloRecebido}
      />
      <Route
        exact={true}
        path="/:prefeitura/confirmar-validacao/"
        component={ConfirmarValidacao}
      />
      <Route
        exact={true}
        path="/:prefeitura/imprimir-iptu/:exercicio"
        component={ImprimirIptu}
      />
      <Route
        exact={true}
        path="/:prefeitura/imprimir-iptu/:exercicio/confirmar/"
        component={ConfirmarDadosIptu}
      />
      <Route
        exact={true}
        path="/:prefeitura/imprimir-ficha-cpbs/"
        component={ImprimirFichaCpbs}
      />
      {estaLogadoValue && obterUserInfo().perfil === "vigilancia_sanitaria" ? (
        <Route
          exact={true}
          path="/:prefeitura/alvara-sanitario/"
          component={AlvarasBox}
        />
      ) : null}

      <Route exact={true} path="/:prefeitura/cda/" component={CdaBox} />
      <Route exact={true} path="/:prefeitura" component={HomePage} />
      {estaLogadoValue ? (
        <Route exact={true} path="/:prefeitura/cnds" component={CndBox} />
      ) : (
        <Route exact={true} path="/:prefeitura/cnds" component={HomePage} />
      )}
      <Route
        exact={true}
        path="/:prefeitura/login"
        render={(cProps) => (
          <LoginPage
            props={{ ...cProps, loginRealizado: props.loginRealizado }}
          />
        )}
      />
      <PrivateRoute
        exact={true}
        path={"/:prefeitura/bloquear-cnd"}
        component={BloquearCnd}
      />
      <Route
        exact={true}
        path="/:prefeitura/nao-autorizado/"
        component={NotAuthPage}
      />
    </div>
  );
};

export default MyRoutes;
