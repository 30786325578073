import React from "react";
import { useParams, Link, Redirect } from "react-router-dom";
import "../home/home.scss";
import { FaFileAlt, FaSearch, FaCheck } from "react-icons/fa";
import { estaLogado } from "../../services/LoginService";
import DataGrid, { Column, Lookup, Editing, SearchPanel } from 'devextreme-react/data-grid';
import { createStore } from "devextreme-aspnet-data-nojquery";
//import { createStore } from "devextreme-aspnet-data-nojquery";
import Editor from "devextreme/ui/editor/editor";
import TextBox from 'devextreme-react/text-box';
import axios from "axios";
import notify from 'devextreme/ui/notify';
import Form, {
    SimpleItem,
    GroupItem,
    Label,
    ButtonItem,
    ButtonOptions
} from 'devextreme-react/form';

let urlBase = "";

const urlBaseDev = 'http://localhost:4040/api';
const urlBaseTestes = "http://45.35.104.141:4040/api";
const urlBaseProd = "http://45.32.167.221:4040/api";

if (process.env.REACT_APP_ESTAGIO === "dev") { urlBase = urlBaseDev; }
else if (process.env.REACT_APP_ESTAGIO === "testes") { urlBase = urlBaseTestes; }
else if (process.env.REACT_APP_ESTAGIO === "prod") { urlBase = urlBaseProd; }

urlBase = "https://apiportal.devtrimap.com.br/api";

class BloquerCnd extends React.Component {

    constructor(props) {
        super(props);

        this.state = { cpfCnpj: "", mensagem: "", prefeitura: "ipaporanga.ce" };

        let prefeitura = this.state.prefeitura;

        this.dataGrid = React.createRef();

        this.moderacoesCndDs = createStore({
            key: "id",
            loadUrl: `${urlBase}/cnd/moderar/${prefeitura}`,
            insertUrl: `${urlBase}/cnd/moderar/${prefeitura}`,
            updateUrl: `${urlBase}/cnd/moderar/${prefeitura}`,
            deleteUrl: `${urlBase}/cnd/moderar/${prefeitura}`
        })

        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.atualizarGrid = this.atualizarGrid.bind(this);
        this.bloquearCpfCnpj = this.bloquearCpfCnpj.bind(this);
        this.notesOptions = { height: 100 };

    }

    atualizarGrid() {
        this.dataGrid.instance.refresh();
    }

    bloquearCpfCnpj() {

        const { cpfCnpj, mensagem, prefeitura } = this.state;

        axios.post(`${urlBase}/cnd/moderar/${prefeitura}`, { cpfCnpj, mensagem })
            .then(() => {
                notify({ message: "Registro salvo com sucesso", closeOnClick: true, position: { at: 'top center', my: 'top center', offset: '0 50' } }, "success", 3000);
                this.atualizarGrid();
                this.setState({ cpfCnpj: "", mensagem: "" });
            })
            .catch(error => {
                notify({ message: error, closeOnClick: true, position: { at: 'top center', my: 'top center', offset: '0 50' } }, "error", 3000);
            })

    }


    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                width: 42,
                icon: 'refresh',
                onClick: this.atualizarGrid
            }
        });
    }

    render() {
        let prefeitura = this.props.match.params.prefeitura;

        if (!estaLogado()) {
            return (
                <Redirect to={`/ ${prefeitura} / `} />
            );
        }

        return (
            <React.Fragment>
                <div className={"content-block"}>
                    <div className={"dx-card responsive-paddings home-container"}>
                        <div className="services-header">
                            <h5>BLOQUEIOS ATIVOS</h5>
                            <Form formData={this.state} labelLocation={"left"} alignItemLabels={true}>

                                <GroupItem cssClass="second-group" colCount={1}>
                                    <SimpleItem dataField="cpfCnpj" editorType={"dxTextBox"} editorOptions={{ width: "250px" }}>
                                        <Label text="CPF/CNPJ" />
                                    </SimpleItem>
                                    <SimpleItem
                                        colSpan={1}
                                        dataField="mensagem"
                                        editorType="dxTextArea"
                                        editorOptions={this.notesOptions}
                                    >
                                        <Label text="Mensagem" />
                                    </SimpleItem>
                                    <ButtonItem alignment={"left"}>
                                        <ButtonOptions
                                            text="Bloquear"
                                            onClick={this.bloquearCpfCnpj} type="success" />
                                    </ButtonItem>
                                </GroupItem>
                            </Form>
                            <DataGrid
                                id="gridContainer"
                                ref={(ref) => this.dataGrid = ref}
                                dataSource={this.moderacoesCndDs}
                                allowColumnReordering={true}
                                allowColumnResizing={true}
                                columnAutoWidth={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                wordWrapEnabled={true}
                                useIcons={true} onToolbarPreparing={this.onToolbarPreparing}>
                                <SearchPanel visible={true}
                                    width={300}
                                    placeholder="Pesquisar..." />
                                <Editing allowDeleting={true} texts={{ confirmDeleteMessage: 'Tem certeza que deseja remover esse bloqueio?' }} />
                                <Column dataField="id" caption="#" visible={false} />
                                <Column dataField="tipoDisplay" caption="Tipo" width={120} />
                                <Column dataField="documentoDisplay" caption="CPF/CNPJ" width={150} />
                                <Column dataField="nomeDisplay" caption="Descritivo" width={250} />
                                <Column dataField="motivo" caption="Motivo" />

                            </DataGrid>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );

    }


}

export default BloquerCnd;