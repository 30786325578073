import React, { Component } from "react";
import {
  DataGrid,
  Column,
  Paging,
  Pager,
  FormItem,
  FilterPanel,
  SearchPanel,
} from "devextreme-react/data-grid";
import uiInfo from "../../ui-info";
import showReport from "../../showReport";
import { criarCndDs, criarCndDsVazia, criarCndPortalDs } from "../../services/DataSources";
import api from "../../services/api";
import { TreeListHeaderFilterTexts } from "devextreme-react/tree-list";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import Form, {
  SimpleItem,
  GroupItem,
  Label,
  EmptyItem,
} from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import { obterUserInfo } from "../../services/LoginService";

export default class CndBox extends Component {
  constructor(props) {
    super(props);
    this._gridInstance = React.createRef();
    this.cndDs = criarCndDsVazia();
    this.state = { carregando: true, prefeitura: this.props.match.params.prefeitura };
    console.log("props cnd box", this.props);
    this.imprimirCnd = this.imprimirCnd.bind(this);
    this.cancelarCnd = this.cancelarCnd.bind(this);
    this.normalizarCnd = this.normalizarCnd.bind(this);
    this.alterarStatusCnd = this.alterarStatusCnd.bind(this);
    this.confirmarAlterarCnd = this.confirmarAlterarCnd.bind(this);
  }

  componentDidMount() {
    let resp = api.get(`/prefeitura/${this.state.prefeitura}`)
      .then(resp => {
        const pref = resp.data;
        console.log("resp data", resp.data);
        if (resp.data.listarCnd == 1) {
          this.cndDs = criarCndDs();
        }
        else if (resp.data.listarCnd == 2) {
          this.cndDs = criarCndPortalDs(this.state.prefeitura);
        }
        this.setState({ carregando: false, listarCnd: resp.data.listarCnd, prefeituraId: resp.data.prefeituraId });

      })

  }

  imprimirCnd(e) {
    console.log("botao pdf click: this state", this.state, "e", e)

    if (this.state.listarCnd == 1) {
      showReport(`/report/cnd/${e.row.data.id}/${this.state.prefeituraId}`);
    }
    else if (this.state.listarCnd == 2) {
      const url = `${api.defaults.baseURL}pdf/cnd/${this.state.prefeitura}/${e.row.data.protocolo.sequencial}`;
      window.open(url, "__blank");
    }
  }

  cancelarCnd(e) {
    if (e.row.data.status == 1) {
      notify("Certidão já está cancelada", "error");
      return;
    }
    this.alterarStatusCnd(e, 1);
  }

  normalizarCnd(e) {
    if (e.row.data.status == 0) {
      notify("Certidão já está normal", "error");
      return;
    }
    this.alterarStatusCnd(e, 2);
  }

  alterarStatusCnd(e, status) {
    console.log("e alterar cnd", e);
    this.setState({ popupAlterarCnd: true, popupAlterarCndStatus: status, popupAlterarCndJustificativa: "", popupAlterarCndMsg: "", eData: e });
  }

  confirmarAlterarCnd() {

    var userInfo = obterUserInfo();

    var request = {
      prefeitura: this.state.prefeitura,
      cndId: this.state.eData.row.data.id,
      operacao: this.state.popupAlterarCndStatus,
      justificativa: this.state.popupAlterarCndJustificativa,
      mensagem: this.state.popupAlterarCndMsg,
      usuarioId: userInfo.id
    };

    api.post(`cnd/alterar_status`, request)
      .then(_ => {
        notify("operacao realizada com sucesso", "success")
        this.setState({ popupAlterarCnd: false });
        console.log("grid inst", this._gridInstance);
        this._gridInstance.instance.refresh();
      })
      .catch(_ => {
        notify("Erro ao cancelar CND", "error");
      });

  }



  render() {
    return (
      <React.Fragment>
        <div className={"content-block dx-card responsive-paddings"}>
          <h4 style={{ textAlign: "center" }}>Lista de certidões de débitos emitidas</h4>
          <DataGrid
            ref={(grid) => (this._gridInstance = grid)}
            dataSource={this.cndDs}
            showBorders={true}
            rowAlternationEnabled={true}
            columnAutoWidth={true}
            focusedRowEnabled={true}
            wordWrapEnabled={true}
            allowColumnReordering={true}
            filterSyncEnabled={true}
          >
            <SearchPanel visible={true}
              width={"400"}
              placeholder="Pesquisar..." />
            <Column
              width={this.state.listarCnd == 2 ? 110 : 60}
              type="buttons"
              fixed={true}
              fixedPosition={"left"}
              buttons={[
                {
                  hint: "Visualizar",
                  icon: "exportpdf",
                  onClick: this.imprimirCnd
                },
                {
                  hint: "Cancelar",
                  icon: "remove",
                  visible: this.state.listarCnd == 2,
                  onClick: this.cancelarCnd
                },
                {
                  hint: "Normalizar",
                  icon: "undo",
                  visible: this.state.listarCnd == 2,
                  onClick: this.normalizarCnd
                }
              ]}
            />

            <Column
              dataField={"id"}
              caption={"#"}
              allowEditing={false}
              width={80}
              alignment={"center"}
              visible={false}
            />

            <Column
              dataField={"protocolo.sequencial"}
              caption={"Protocolo"}
              allowEditing={false}
              width={90}
              alignment={"center"}
              visible={this.state.listarCnd == 2}
            />

            <Column
              dataField={"ano"}
              caption={"Ano"}
              allowEditing={false}
              width={120}
              alignment={"center"}
              sortIndex={1}
              sortOrder={"desc"}
              visible={false}
            />

            <Column
              dataField={"numero"}
              caption={"Nº"}
              allowEditing={false}
              width={120}
              alignment={"center"}
              sortIndex={1}
              sortOrder={"desc"}
              visible={false}
            />


            <Column
              dataField={"prefeituraId"}
              caption={"Prefeitura"}
              visible={false}
            >
              <FormItem visible={false}></FormItem>
            </Column>

            <Column
              dataField={"tipoDeCertidao.tipoCadastro.descricao"}
              caption={"Tipo"}
              width={140}
            />

            <Column
              dataField={"data"}
              caption={"Emissão"}
              dataType={"date"}
              width={120}
            />

            <Column
              dataField={"statusCndStr"}
              caption={"Situação"}
              width={100}
            />

            <Column
              dataField={"numeroCompleto"}
              caption={"Número"}
              width={100}
            />

            <Column
              dataField={"dataDeValidade"}
              caption={"Validade"}
              dataType={"date"}
              width={110}
            />

            <Column dataField={"inscricao"} caption={"Inscrição"} width={120} />

            <Column dataField={"contribuinte.cpfCnpj"} caption={"CPF/CNPJ Orig."} visible={false} />

            <Column dataField={"contribuinte.documentoFormatado"} caption={"CPF/CNPJ"} width={160} />

            <Column dataField={"contribuinte.nome"} caption={"Contribuinte"} />

            <Paging defaultPageSize={uiInfo.tamanhoPaginaPadrao} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={uiInfo.tamanhoPaginaOpcoes}
            />
          </DataGrid>


          <Popup
            visible={this.state.popupAlterarCnd}
            onHiding={(e) => this.setState({ popupAlterarCnd: false })}
            dragEnabled={false}
            closeOnOutsideClick={true}
            showCloseButton={true}
            showTitle={true}
            width={500}
            height={400}
            title={this.state.popupAlterarCndStatus == 1 ? "CANCELAR CERTIDÃO" : "NORMALIZAR CERTIDÃO"}
          >
            <form>
              <Form formData={this.state}>
                <SimpleItem dataField={"popupAlterarCndJustificativa"} height={"50%"} editorType={"dxTextArea"} height={250}>
                  <Label text="Justificativa" />
                </SimpleItem>

                <SimpleItem dataField={"popupAlterarCndMsg"} height={"50%"} editorType={"dxTextArea"} height={250}>
                  <Label text="Mensagem ao Visualizar" />
                </SimpleItem>
              </Form>
            </form>

            <ToolbarItem
              toolbar={"bottom"}
              widget="dxButton"
              location="center"
              options={{
                text: "Confirmar",
                type: "success",
                width: 150,
                onClick: this.confirmarAlterarCnd,
              }}
            ></ToolbarItem>
            <ToolbarItem
              toolbar={"bottom"}
              widget="dxButton"
              location="center"
              options={{
                text: "Cancelar",
                type: "default",
                width: 150,
                onClick: () => this.setState({ popupAlterarCnd: false }),
              }}
            ></ToolbarItem>
          </Popup>
        </div>
      </React.Fragment>
    );
  }
}
