import React from "react";
import {
  DataGrid,
  Column,
  Paging,
  Pager,
  Lookup,
  FormItem,
} from "devextreme-react/data-grid";
import uiInfo from "../../ui-info";
import Form, { SimpleItem, Label } from "devextreme-react/form";
import { Button } from "devextreme-react";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import {
  imprimirAlvara,
  imprimirDamFunction,
} from "../../utils/lancamentosUtils";
import notify from "devextreme/ui/notify";
import apiSgtWeb from "../../services/apiSgtWeb";
import "./index.scss";
import { obterUserInfo } from "../../services/LoginService";

export default class AlvarasBox extends React.Component {
  constructor(props) {
    super(props);
    this._gridInstance = React.createRef();
    this.filtrar = this.filtrar.bind(this);
    this.limparFiltro = this.limparFiltro.bind(this);
    this.alterarStatus = this.alterarStatus.bind(this);
    this.state = {
      filtro: {
        exercicio: undefined,
        cpfCnpj: undefined,
        dataInicial: undefined,
        dataFinal: undefined,
        valorInicial: undefined,
        valorFinal: undefined,
      },
      alvaras: [],
      showPopup: false,
      alvaraStatusData: {},
      alvaraId: undefined,
    };

    this.statusOptions = {
      editorType: "dxSelectBox",
      searchEnabled: false,
      dataSource: [
        { id: 0, descricao: "GERADO" },
        { id: 1, descricao: "PAGO/DISPENSADO" },
        { id: 2, descricao: "EMITIDO" },
        { id: 3, descricao: "CANCELADO" },
        { id: 4, descricao: "SUSPENSO" },
        { id: 5, descricao: "CASSADO" },
        { id: 6, descricao: "EXCLUÍDO" },
      ],
      valueExpr: "id",
      displayExpr: "descricao",
      showClearButton: false,
    };

    this.statusAlvaraOptions = {
      editorType: "dxSelectBox",
      searchEnabled: false,
      dataSource: [
        { id: 0, descricao: "Nenhum" },
        { id: 1, descricao: "Pendente" },
        { id: 2, descricao: "Em Andamento" },
        { id: 3, descricao: "Liberado" },
        { id: 4, descricao: "Regularização" },
        { id: 5, descricao: "Indeferido" },
      ],
      valueExpr: "id",
      displayExpr: "descricao",
    };

    this.filtrarOpcoes = {
      type: "success",
      text: "Filtrar",
    };
    this.limparFiltroOpcoes = {
      text: "Limpar",
    };
  }

  limparFiltro() {
    this.setState({ filtro: {} });

    this.filtrar();
  }

  filtrar() {
    let filtro = [];
    let estado = this.state.filtro;

    if (estado.exercicio) {
      filtro.push(["exercicio", "=", estado.exercicio]);
    }

    if (estado.numero) {
      filtro.push(["numero", "=", estado.numero]);
    }

    if (estado.nome) {
      filtro.push(["nome", "contains", estado.nome]);
    }

    if (estado.cpfCnpj) {
      filtro.push(["cpfCnpj", "contains", estado.cpfCnpj]);
    }

    if (estado.dataInicial) {
      const data = estado.dataInicial;
      filtro.push([
        "dataEmissao",
        ">=",
        new Date(
          data.getFullYear(),
          data.getMonth(),
          data.getDate(),
          0,
          0,
          0,
          0
        ),
      ]);
    }

    if (estado.dataFinal) {
      const data = estado.dataFinal;
      filtro.push([
        "dataEmissao",
        "<=",
        new Date(
          data.getFullYear(),
          data.getMonth(),
          data.getDate(),
          23,
          5,
          59,
          999
        ),
      ]);
    }

    if (estado.valorInicial) {
      filtro.push(["valorImposto", ">=", estado.valorInicial]);
    }

    if (estado.valorFinal) {
      filtro.push(["valorImposto", "<=", estado.valorFinal]);
    }

    if (estado.status) {
      filtro.push(["status", "=", estado.status]);
    }

    if (estado.statusLiberacao) {
      filtro.push(["statusLiberacao", "=", estado.statusLiberacao]);
    }

    if (filtro.length > 0) {
      this._gridInstance.instance.filter(filtro);
    } else {
      this._gridInstance.instance.clearFilter();
    }

    this._gridInstance.instance.refresh();
  }

  async componentDidMount() {
    const prefeitura = this.props.match.params.prefeitura;
    const response = await apiSgtWeb.get(
      `/lancamentos/alvaras/tlas_portal/${prefeitura}`
    );
    if (response.status === 200 && response.data) {
      this.setState({ alvaras: response.data.data, prefeitura });
    }
    this.setState({ filtro: {} });
  }

  async alterarStatus(e) {
    const usuarioId = obterUserInfo().id;
    if (this.state.alvaraId && usuarioId) {
      const request = { ...this.state.alvaraStatusData, usuarioId };
      const response = await apiSgtWeb.post(
        `/lancamentos/portal/vigilancia_sanitaria/${this.state.alvaraId}`,
        request
      );
      if (response.status === 200) {
        const fetchAlvaras = await apiSgtWeb.get(
          `/lancamentos/alvaras/tlas_portal/${this.state.prefeitura}`
        );
        if (fetchAlvaras.status === 200 && fetchAlvaras.data) {
          this.setState({ alvaras: fetchAlvaras.data.data, showPopup: false });
        }
      }
    }
  }

  render() {
    return (
      <div className={"content-block dx-card responsive-paddings"}>
        <h2 style={{ textAlign: "center" }}>ALVARÁS SANITÁRIOS</h2>
        <form onSubmit={this.filtrar}>
          <Form
            id="frmPesquisa"
            formData={this.state.filtro}
            colCount={6}
            labelLocation="top"
          >
            <SimpleItem
              dataField="exercicio"
              colSpan={1}
              dataType={"number"}
              editorType={"dxNumberBox"}
            >
              <Label text="Exercício" />
            </SimpleItem>
            <SimpleItem
              dataField="numero"
              colSpan={1}
              dataType={"number"}
              editorType={"dxNumberBox"}
            >
              <Label text="Número" />
            </SimpleItem>
            <SimpleItem dataField="cpfCnpj" colSpan={2}>
              <Label text="CPF/CNPJ" />
            </SimpleItem>
            <SimpleItem dataField="nome" colSpan={2}>
              <Label text="Razão Social" />
            </SimpleItem>
            <SimpleItem
              dataField="dataInicial"
              dataType={"date"}
              editorType={"dxDateBox"}
              editorOptions={{ width: "100%", useMaskBehavior: true }}
            >
              <Label text="Data Inicial" />
            </SimpleItem>

            <SimpleItem
              dataField="dataFinal"
              dataType={"date"}
              editorType={"dxDateBox"}
              editorOptions={{ width: "100%", useMaskBehavior: true }}
            >
              <Label text="Data Final" />
            </SimpleItem>

            <SimpleItem
              dataField="valorInicial"
              editorType={"dxNumberBox"}
              editorOptions={{ format: uiInfo.formatoNumerico.n2 }}
              cssClass={"txtFrmCpbsNumerico"}
            >
              <Label text="Vr. Inicial (R$)" />
            </SimpleItem>

            <SimpleItem
              dataField="valorFinal"
              editorType={"dxNumberBox"}
              editorOptions={{ format: uiInfo.formatoNumerico.n2 }}
              cssClass={"txtFrmCpbsNumerico"}
            >
              <Label text="Vr. Final (R$)" />
            </SimpleItem>
            <SimpleItem
              dataField="status"
              colSpan={2}
              editorType={"dxLookup"}
              editorOptions={this.statusOptions}
            >
              <Label text="Situação" />
            </SimpleItem>
            <SimpleItem
              dataField="statusLiberacao"
              colSpan={2}
              editorType={"dxLookup"}
              editorOptions={this.statusAlvaraOptions}
            >
              <Label text="Situação Liberação" />
            </SimpleItem>
          </Form>
          <div style={{ marginTop: "20px", marginBottom: "10px" }}>
            <div style={{ display: "inline-block" }}>
              <Button
                width={120}
                text="Filtrar"
                type="success"
                onClick={this.filtrar}
              />
            </div>
            <div style={{ display: "inline-block", marginLeft: "10px" }}>
              <Button text="Limpar Filtro" onClick={this.limparFiltro} />
            </div>
          </div>
        </form>
        <p className="alvaras-titulo">Alvarás Sanitários</p>
        <DataGrid
          ref={(grid) => (this._gridInstance = grid)}
          dataSource={this.state.alvaras}
          showBorders={true}
          rowAlternationEnabled={true}
          remoteOperations={true}
          columnAutoWidth={true}
          allowColumnReordering={true}
          key={"id"}
        >
          <Paging defaultPageSize={uiInfo.tamanhoPaginaPadrao} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={uiInfo.tamanhoPaginaOpcoes}
            showInfo={true}
          />
          <Column
            dataField={"entidadeId"}
            visible={false}
            showInColumnChooser={false}
          >
            <FormItem visible={false}></FormItem>
          </Column>
          <Column
            type="buttons"
            fixed={true}
            fixedPosition={"left"}
            buttons={[
              {
                hint: "DAM",
                icon: "money",
                onClick: (e) => {
                  const data = e.row.data;
                  if (data.status !== 0 || data.posicaoFiscal !== 0) {
                    notify(
                      "Status ou Posição Fiscal do alvará não permite impressão do DAM.",
                      "error"
                    );
                    return;
                  }
                  imprimirDamFunction(
                    data.prefeituraId,
                    data.lancamentoId,
                    data.chaveLancamento
                  );
                },
              },
              {
                hint: "Alvará",
                icon: "exportpdf",
                onClick: (e) => {
                  imprimirAlvara(e.row.data.id);
                },
              },
              {
                hint: "Alterar Status",
                icon: "edit",
                onClick: ({ row }) =>
                  this.setState({
                    showPopup: true,
                    alvaraId: row.data.id,
                    alvaraStatusData: {
                      status: row.data.statusLiberacao,
                      historico: row.data.atividade,
                    },
                  }),
              },
            ]}
          />
          <Column dataField={"exercicio"} caption={"Exercício"} width={80} />
          <Column
            dataField={"dataEmissao"}
            caption={"Emissão"}
            dataType={"date"}
            width={120}
            sortIndex={0}
            sortOrder={"desc"}
          />
          <Column
            dataField={"validade"}
            caption={"Dt. Vencimento"}
            dataType={"date"}
            width={120}
          />
          <Column dataField={"numero"} caption={"Número"} width={90} />
          <Column dataField={"nome"} caption={"Razão Social"} />
          <Column
            dataField={"cpfCnpjFormatado"}
            caption={"Documento"}
            dataType={"date"}
          />
          <Column dataField={"enderecoCompletoDisplay"} caption={"Endereço"} />
          <Column dataField={"status"} caption={"Status"} width={160}>
            <Lookup
              dataSource={this.statusOptions.dataSource}
              displayExpr={"descricao"}
              valueExpr={"id"}
            />
          </Column>
          <Column
            dataField={"statusLiberacao"}
            caption={"Situação da Liberação"}
            width={160}
          >
            <Lookup
              dataSource={this.statusAlvaraOptions.dataSource}
              displayExpr={"descricao"}
              valueExpr={"id"}
            />
          </Column>
          <Column
            dataField={"usuarioLiberacaoId"}
            caption={"Usuário da Liberação"}
          />
          <Column
            dataField={"valorImposto"}
            caption={"Tributos (R$)"}
            dataType={"number"}
            format={uiInfo.formatoNumerico.n2}
          />
        </DataGrid>
        <Popup
          visible={this.state.showPopup}
          onHiding={(e) => this.setState({ showPopup: false })}
          dragEnabled={false}
          closeOnOutsideClick={true}
          showCloseButton={true}
          showTitle={true}
          width={700}
          height={580}
          title={"Alterar Situação do Alvará"}
        >
          <form>
            <Form formData={this.state.alvaraStatusData}>
              <SimpleItem
                dataField={"status"}
                editorType={"dxLookup"}
                editorOptions={this.statusAlvaraOptions}
              >
                <Label text="Situação" />
              </SimpleItem>
              <SimpleItem dataField={"historico"} editorType={"dxTextArea"}>
                <Label text="Histórico" />
              </SimpleItem>
              <SimpleItem
                dataField={"obs"}
                editorType={"dxTextArea"}
                editorOptions={{ height: "90px" }}
              >
                <Label text="Obersvação" />
              </SimpleItem>
            </Form>
          </form>
          <ToolbarItem
            toolbar={"bottom"}
            widget="dxButton"
            location="center"
            options={{
              text: "Fechar",
              type: "default",
              width: 150,
              onClick: () => this.setState({ showPopup: false }),
            }}
          />
          <ToolbarItem
            toolbar={"bottom"}
            widget="dxButton"
            location="center"
            options={{
              text: "Confirmar",
              type: "success",
              width: 150,
              onClick: this.alterarStatus,
            }}
          />
        </Popup>
      </div>
    );
  }
}
