import React, { useState } from "react";
import "./index.scss";
import api from "../../services/api";
import { FaCheck } from "react-icons/fa";
import { useParams, useHistory } from "react-router-dom";
import notify from "devextreme/ui/notify";
import ReCAPTCHA from "react-google-recaptcha";
import captchaKey from '../../tests/captchakey';

const ValidarCnd = () => {
  const [codigo, setCodigo] = useState("");
  const [captcha, setCaptcha] = useState({
    permission: false,
  });
  const history = useHistory();
  const { prefeitura } = useParams();

  function handleReCaptchaSize() {
    let smallSize = window.matchMedia("(max-width: 900px)");
    if (smallSize.matches) {
      return "compact";
    } else {
      return "normal";
    }
  }

  function onChange(e) {
    setCaptcha({ ...captcha, permission: true });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (captcha.permission) {
      try {
        
        const response = await api.post(
          
          `cnd/validar/${prefeitura}/${codigo.replace("/", "-")}`
        );
        if (response.status === 200) {
            history.replace({
              pathname: `/${prefeitura}/confirmar-validacao/`,
              props: response.data,
            });

        } else {
            notify(
                {
                  message: "Código de Validação Inválido. Verifique-o e tente novamente!",
                  closeOnClick: true,
                  position: { at: "top center", my: "top center", offset: "0 50" },
                },
                "error",
                10000
              );
        }
      } catch (err) {
        notify(
          {
            message: "Código de Validação Inválido. Verifique-o e tente novamente!",
            closeOnClick: true,
            position: { at: "top center", my: "top center", offset: "0 50" },
          },
          "error",
          10000
        );
      }
    } else {
      alert("É necessário responder ao ReCaptcha!");
    }
  }
  return (
    <React.Fragment>
      <div className={"content-block dx-card responsive-paddings"}>
        <div className="card-content">
          <form onSubmit={(event) => handleSubmit(event)}>
            <div className="input-group">
              <input
                placeholder="Digite o código de validação..."
                className="card-input"
                type="text"
                onChange={({ target }) => setCodigo(target.value)}
              />
              <button type="submit" className="button">
                <FaCheck /> Validar
              </button>
            </div>
            <div>
              <ReCAPTCHA
                className="captcha"
                hl="pt-BR"
                sitekey={captchaKey.key}
                onChange={onChange}
                onExpired={() =>
                  setCaptcha({
                    ...captcha,
                    permission: false,
                  })
                }
                size={handleReCaptchaSize()}
              />
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ValidarCnd;
