import notify from "devextreme/ui/notify";
import api from "../services/apiSgtWeb";
import ShowError from "../AppServices";
import showReport from "../showReport";

export const abrirDetalheFuncao = (history, lancamentoSeq) => {
  history.push(`/detalhelancamento/${lancamentoSeq}`);
};

export const abrirDetalhe = (history, lancamento) => {
  history.push(`/detalhelancamento/${lancamento.sequencial}`);
};

export const abrirDetalheMigrado = (history, chaveLancamento) => {
  history.push(`/detalhelancamento_migrado/${chaveLancamento}`);
};

export const imprimirAlvara = (id) => {
  showReport(`report/alvara/LINKDIRETO_TLAS/${id}`);
};

export const imprimirDam = (lancamento, imprimePix) => {
  if (lancamento.situacaoStr != "N") {
    ShowError("Situação do lançamento não permite visualização do DAM.");
    return;
  }
  if (imprimePix) {
    showReport(
      `report/dam2_geral/${lancamento.id}/${lancamento.prefeituraId}/${imprimePix}`
    );
  } else {
    showReport(
      `report/dam2_geral/${lancamento.id}/${lancamento.prefeituraId}/${imprimePix}`
    );
  }
};

export const imprimirDamFunction = (
  prefeituraId,
  lancamentoId,
  chaveLancamento
) => {
  // if (lancamento.status != 0 || lancamento.posicaoFiscal != 0) {
  //     ShowError("Status ou situação do alvará não permite visualização do DAM.")
  //     return;
  // }
  if (lancamentoId == undefined) {
    showReport(
      `report/dam2_geral_chave_lancamento/${chaveLancamento}/${prefeituraId}`
    );
  } else {
    showReport(`report/dam2_geral/${lancamentoId}/${prefeituraId}/true`);
  }
};

export const abrirLancamentoPorAlvaraView = (history, alvaraView) => {
  if (alvaraView.status != 0 || alvaraView.posicaoFiscal != 0) {
    notify("Operação não permitida", "error");
    return;
  }

  if (alvaraView.lancamentoSeq == undefined) {
    notify("Registro de migração, edição não permitida!");
  }

  abrirLancamentoFuncao(history, alvaraView.tipo, alvaraView.lancamentoSeq);
};

export const abrirLancamento = (history, lancamento) => {
  if (lancamento.pago || lancamento.situacaoStr !== "N") {
    notify("Operação não permitida", "error");
    return;
  }

  abrirLancamentoFuncao(history, lancamento.tipoStr, lancamento.sequencial);
};

export const abrirLancamentoFuncao = (history, tipo, lancamentoSeq) => {
  if (tipo === "TLTA") {
    history.push(`/alvara_veiculo/${lancamentoSeq}`);
  } else if (tipo === "TLLF") {
    history.push(`/alvara_funcionamento/${lancamentoSeq}`);
  } else if (tipo === "TLAS") {
    history.push(`/alvara_sanitario/${lancamentoSeq}`);
  } else if (tipo == "TLFHE") {
    history.push(`/alvara_horario_especial/${lancamentoSeq}`);
  } else if (tipo == "TLVP") {
    history.push(`/alvara_publicidade/${lancamentoSeq}`);
  } else if (tipo == "TLULP") {
    history.push(`/alvara_logradouro_publico/${lancamentoSeq}`);
  } else if (tipo == "TLAD") {
    history.push(`/alvara_diversos/${lancamentoSeq}`);
  } else if (tipo == "TLAC") {
    history.push(`/alvara_construcao/${lancamentoSeq}`);
  } else if (tipo == "TLHB") {
    history.push(`/habitese/${lancamentoSeq}`);
  } else if (tipo == "TAXA") {
    history.push(`/lancamentotaxas/editar/${lancamentoSeq}`);
  } else if (tipo == "ITBI") {
    history.push(`/itbi/editar/${lancamentoSeq}`);
  }
};

export const detalheNotaAvulsa = (history, nf) => {
  history.push(`/detalhenfavulsa/${nf.sequencial}`);
};

export const imprimirNotaAvulsa = (nf) => {
  showReport(`report/nf_avulsa/${nf.prefeituraId}/${nf.id}`);
};

export const imprimirDamsNotaAvulsa = (nf) => {
  showReport(
    `report/nf_avulsa_dams/${nf.prefeituraId}/${nf.sequencial}/${nf.usaPix}`
  );
};

export const imprimirReciboNotaAvulsa = (nf) => {
  showReport(`report/nf_avulsa_recibo/${nf.prefeituraId}/${nf.sequencial}`);
};

export const abrirNotaAvulsa = (history, nf) => {
  api
    .get(`/nota_fiscal_avulsa/pode_editar/${nf.prefeituraId}/${nf.sequencial}`)
    .then((resp) => {
      if (resp.data.podeEditar) {
        history.push(`/emissao_nf_avulsa/${nf.sequencial}`);
      } else {
        ShowError(resp.data.msg);
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        ShowError(error.response.data);
      } else {
        ShowError(error);
      }
    });
};
