import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import "./emitirCndGlobal.scss";
import Form, { SimpleItem, GroupItem, Label } from "devextreme-react/form";
import api from "../../services/api";
import notify from "devextreme/ui/notify";
import captchaKey from "../../tests/captchakey";

const ImprimirIptu = (props) => {
  const [data, setData] = useState({
    inscricao: "",
    cpfCnpj: "",
    permission: false,
    tipoBusca: 1,
  });
  const history = useHistory();
  const { prefeitura, exercicio } = useParams();

  const buscaOptions = {
    editorType: "dxSelectBox",
    searchEnabled: false,
    dataSource: [
      { id: 0, descricao: "CPF/CNPJ" },
      { id: 1, descricao: "INSCRIÇÃO" },
    ],
    valueExpr: "id",
    displayExpr: "descricao",
    onValueChanged: (e) => setData({ ...data, tipoBusca: e.value }),
  };

  function handleReCaptchaSize() {
    let smallSize = window.matchMedia("(max-width: 900px)");
    if (smallSize.matches) {
      return "compact";
    } else {
      return "normal";
    }
  }

  function updateData() {
    //Começo:
    //Esse trecho de programa serve para tratar espaços extras colocados pelo usuário. Medida de segurança.
    //
    let doc = data.cpfCnpj;
    let subs = data.inscricao;
    subs = subs.replace(/ /g, "");
    doc = doc
      .replace(/ /g, "")
      .replace(/\./, "")
      .replace(/-/, "")
      .replace(/\//, "");

    setData({ ...data, inscricao: subs, cpfCnpj: doc });
    //
    //Fim da verificação.
  }

  function onChange(e) {
    setData({ ...data, permission: true });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    updateData();

    if (data.permission !== false) {
      try {
        if (data.tipoBusca === 0) {
          const response = await api.post(
            `impressao_iptu/por_documento/${prefeitura}/${data.cpfCnpj}/${exercicio}`
          );
          if (response.status === 200) {
            history.push({
              pathname: `/${prefeitura}/imprimir-iptu/${exercicio}/confirmar/`,
              props: response.data,
            });
          } else {
            alert("Não foi possível realizar a solicitação.");
          }
        } else {
          const response = await api.post(
            `impressao_iptu/por_inscricao/${prefeitura}/${data.inscricao}/${exercicio}`
          );
          if (response.status === 200) {
            history.push({
              pathname: `/${prefeitura}/imprimir-iptu/${exercicio}/confirmar/`,
              props: response.data,
            });
          } else {
            alert("Não foi possível realizar a solicitação.");
          }
        }
      } catch (err) {
        let msg = "Os dados utilizados são inválidos. Tente novamente.";
        if (err.response && err.response.data) {
          msg = err.response.data;
        }
        notify(
          {
            message: msg,
            closeOnClick: true,
            position: { at: "top center", my: "top center", offset: "0 50" },
          },
          "error",
          5000
        );
      }
    } else alert("É necessário responder o ReCAPTCHA");
  }

  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"my-card responsive-paddings"}>
          <div className="input-box">
            <h2>IPTU {exercicio}</h2>
            <form onSubmit={handleSubmit}>
              <Form formData={data} colCount={1} labelLocation={"top"}>
                <GroupItem>
                  <SimpleItem
                    dataField={"tipoBusca"}
                    editorType={"dxLookup"}
                    editorOptions={buscaOptions}
                  >
                    <Label text="Buscar por" />
                  </SimpleItem>
                  <SimpleItem
                    dataField={"inscricao"}
                    visible={data.tipoBusca === 1 ? true : false}
                  >
                    <Label text="Inscrição do imóvel" />
                  </SimpleItem>
                  <SimpleItem
                    dataField={"cpfCnpj"}
                    visible={data.tipoBusca === 0 ? true : false}
                  >
                    <Label text="CPF ou CNPJ (Somente números)" />
                  </SimpleItem>
                </GroupItem>
              </Form>
              <ReCAPTCHA
                className="captcha"
                hl="pt-BR"
                sitekey={captchaKey.key}
                onChange={onChange}
                size={handleReCaptchaSize()}
                onExpired={() => setData({ ...data, permission: false })}
              />
              <button type="submit" className="button">
                Buscar Dados
              </button>
            </form>
            <p>( * ) Obrigatório Inscrição do Imóvel ou CPF/CNPJ</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ImprimirIptu;
