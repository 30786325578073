import axios from "axios";
import ambiente from "./ambiente";
import { obterUserInfo } from "./LoginService";

const api = axios.create({
  baseURL: ambiente.URL_API_SGTWEB,
});

api.interceptors.request.use(async (config) => {
  if (obterUserInfo() && obterUserInfo().token) {
    config.headers = {
      Authorization: `Bearer ${obterUserInfo().token}`,
      "Access-Control-Allow-Origin": "*",
    };
  } else {
    config.headers = {
      "Access-Control-Allow-Origin": "*",
    };
  }
  return config;
});

export default api;
