import React, { useState } from "react";
import "./index.scss";
import api from "../../services/api";
import { FaSearch } from "react-icons/fa";
import { useParams, useHistory } from "react-router-dom";
import notify from "devextreme/ui/notify";
import ReCAPTCHA from "react-google-recaptcha";
import captchaKey from '../../tests/captchakey';

const BuscarProtocolo = () => {
  const [protocolo, setProtocolo] = useState("");
  const [documento, setDocumento] = useState("");
  const [captcha, setCaptcha] = useState({
    permission: false,
  });
  const history = useHistory();
  const { prefeitura } = useParams();

  function handleReCaptchaSize() {
    let smallSize = window.matchMedia("(max-width: 900px)");
    if (smallSize.matches) {
      return "compact";
    } else {
      return "normal";
    }
  }

  function onChange(e) {
    setCaptcha({ ...captcha, permission: true });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (captcha.permission) {
      try {
        const response = await api.get(
          `protocolo/${prefeitura}/${protocolo.replace(/ /g, "")}`
        );
        if (response.status === 200) {
          let doc = response.data.contribuinte.cpfCnpj
            .replace(/ /g, "")
            .replace(/\./g, "")
            .replace(/-/g, "")
            .replace(/\//g, "");
          if (doc === documento) {
            history.replace({
              pathname: `/${prefeitura}/acompanhamento/`,
              props: response.data,
            });
          } else {
            notify(
              {
                message: "Documento inválido. Tente novamente!",
                closeOnClick: true,
                position: {
                  at: "top center",
                  my: "top center",
                  offset: "0 50",
                },
              },
              "error",
              10000
            );
          }
        } else if (!response.data.ok) {
          notify(
            {
              message: "Protocolo não encontrado!",
              closeOnClick: true,
              position: { at: "top center", my: "top center", offset: "0 50" },
            },
            "error",
            10000
          );
        }
      } catch (err) {
        notify(
          {
            message: "Dados inválidos. Verifique-os e tente novamente!",
            closeOnClick: true,
            position: { at: "top center", my: "top center", offset: "0 50" },
          },
          "error",
          10000
        );
      }
    } else {
      alert("É necessário responder ao ReCaptcha!");
    }
  }
  return (
    <React.Fragment>
      <div className={"content-block dx-card responsive-paddings"}>
        <div className="card-content">
          <form onSubmit={(event) => handleSubmit(event)}>
            <div className="input-group">
              <input
                placeholder="Digite o protocolo"
                className="card-input"
                type="text"
                onChange={({ target }) => setProtocolo(target.value)}
              />
              <input
                placeholder="Digite o CPF ou CNPJ (Somente números)"
                className="card-input"
                type="text"
                onChange={({ target }) =>
                  setDocumento(
                    target.value
                      .replace(/ /g, "")
                      .replace(/\./g, "")
                      .replace(/-/g, "")
                      .replace(/\//g, "")
                  )
                }
              />
              <button type="submit" className="button">
                <FaSearch /> Pesquisar Protocolo
              </button>
            </div>
            <div>
              <ReCAPTCHA
                className="captcha"
                hl="pt-BR"
                sitekey={captchaKey.key}
                onChange={onChange}
                onExpired={() =>
                  setCaptcha({
                    ...captcha,
                    permission: false,
                  })
                }
                size={handleReCaptchaSize()}
              />
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BuscarProtocolo;
