import ShowError from "./AppServices";
import notify from "devextreme/ui/notify";
import apiSgtWeb from "./services/apiSgtWeb";
import { findAllInRenderedTree } from "react-dom/test-utils";

// function base64toBlob(base64Data, contentType) {
//     contentType = contentType || '';
//     var sliceSize = 1024;
//     var byteCharacters = atob(base64Data);
//     var bytesLength = byteCharacters.length;
//     var slicesCount = Math.ceil(bytesLength / sliceSize);
//     var byteArrays = new Array(slicesCount);

//     for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
//         var begin = sliceIndex * sliceSize;
//         var end = Math.min(begin + sliceSize, bytesLength);

//         var bytes = new Array(end - begin);
//         for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
//             bytes[i] = byteCharacters[offset].charCodeAt(0);
//         }
//         byteArrays[sliceIndex] = new Uint8Array(bytes);
//     }
//     return new Blob(byteArrays, { type: contentType });
// }

const showReportString = (rptStr) => {
  const bin = atob(rptStr);
  const length = bin.length;
  const arrayBuffer = new ArrayBuffer(length);
  const uintArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < length; i++) {
    uintArray[i] = bin.charCodeAt(i);
  }

  const fileBlob = new Blob([uintArray], { type: "application/pdf" });
  const fileURL = URL.createObjectURL(fileBlob);

  window.open(fileURL, "_blank");
};

export { showReportString };

export default (url, requestBody) => {
  return apiSgtWeb
    .post(url, {
      ...requestBody,
      responseType: "blob",
    })
    .then((response) => {
      if (response.data.sucesso) {
        const bin = atob(response.data.report);
        const length = bin.length;
        const arrayBuffer = new ArrayBuffer(length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < length; i++) {
          uintArray[i] = bin.charCodeAt(i);
        }

        const fileBlob = new Blob([uintArray], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(fileBlob);

        window.open(fileURL, "_blank");
      } else {

        if (response.data && response.data.erros && Array.isArray(response.data.erros) && response.data.erros.length >= 1) {
          notify(response.data.erros[0], "error");
        }
        else {
          notify("Relatório não encontrado.", "error");
        }
      }

      return response;
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        // showError(error.response.data);
        console.log("show error 1", error.response.data);
      } else {
        // showError(error);
        console.log("show error 2", error);
      }

      alert(error);

      return Promise.reject(error);
    });
};
