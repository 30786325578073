import React from "react";
import { estaLogado, obterUserInfo } from "../../services/LoginService";
import DataGrid, {
  Column,
  Lookup,
  Editing,
  SearchPanel,
} from "devextreme-react/data-grid";
import { createStore } from "devextreme-aspnet-data-nojquery";
import notify from "devextreme/ui/notify";
import Form, {
  SimpleItem,
  GroupItem,
  Label,
  ButtonItem,
  ButtonOptions,
} from "devextreme-react/form";
import api from "../../services/apiSgtWeb";
import { Button, LoadIndicator } from "devextreme-react";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import showReport, { showReportString } from "../../showReport";

class CdaBox extends React.Component {
  constructor(props) {
    super(props);
    this._gridInstance = React.createRef();
    this._formInstance = React.createRef();
    this.state = {
      cdaDs: [],
      filtro: {},
      popupVisible: false,
      dataVencimento: null,
      _uiExibirBotaoDam: false,
      _uiMsgCarregando: "",
      _uiTextoGrid: "Preencha o campo 'Protocolo Distribuição' e clique em 'Filtrar' para exibir as informações nesta grade."
    };

    this.filtrar = this.filtrar.bind(this);
    this.limparFiltro = this.limparFiltro.bind(this);
    this.fetchCdaData = this.fetchCdaData.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  async componentDidMount() {

    var userInfo = obterUserInfo();

    const prefeitura = this.props.match.params.prefeitura;

    if (userInfo && userInfo.codigoCartorio && userInfo.codigoCartorio != 0) {

      this.setState({ cdaDs: [], prefeitura: prefeitura });
    }
    else {
      this.props.history.push(`/${prefeitura}`);

    }
  }

  async fetchCdaData(prefeitura, protocolo) {

    const userInfo = obterUserInfo();

    if (userInfo && userInfo.codigoCartorio) {

      const codigoCartorio = userInfo.codigoCartorio;

      try {
        const response = await api.get(`/cda/grade_cartorio/${prefeitura}/${codigoCartorio}/${protocolo}`);
        if (response.data && response.status === 200) {
          return response.data.data;
        }
      }
      catch (error) {
        console.log(error);
      }

    }

    return [];

  }

  async filtrar(e) {
    try {

      let filtro = this.state.filtro;

      if (filtro.protocoloProtesto == undefined || filtro.protocoloProtesto == null || filtro.protocoloProtesto == "") {
        notify("Por favor preencha o protocolo.", "error");
        return;
      }

      this.setState({ _uiMsgCarregando: "Buscando informações...", _uiTextoGrid: `Protocolo '${filtro.protocoloProtesto}' não encontrado.` });


      const prefeitura = this.props.match.params.prefeitura;

      if (prefeitura) {
        const cdaDs = await this.fetchCdaData(prefeitura, filtro.protocoloProtesto);
        this.setState({ cdaDs: cdaDs, prefeitura: prefeitura });
      }
    }
    finally {
      this.setState({ _uiMsgCarregando: "" });
    }



  }

  limparFiltro() {
    this.setState({ filtro: {}, cdaDs: [] });
  }

  onKeyPress(e) {
    const keys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    const event = e.event;
    if (!keys.includes(event.key)) {
      event.stopPropagation();
      event.preventDefault();

    }

  }

  render() {

    if (this.state._uiMsgCarregando != "") {
      return (
        <div className={"content-block dx-card responsive-paddings"}>
          <div
            style={{
              display: "flex",
              marginTop: "25px",
              justifyContent: "center",
              alignContent: "center",
              justifyItems: "center",
              alignItems: "center",
              flexDirection: "column"
            }}
          >
            <LoadIndicator width={60} height={60} />
            <h3>{this.state._uiMsgCarregando}</h3>
          </div>
        </div>
      );
    }


    return (
      <React.Fragment>
        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings home-container"}>
            <h4>CDA Protesto</h4>
            <form onSubmit={this.filtrar} style={{ padding: "0px 15px" }}>
              <Form
                id="frmPesquisa"
                formData={this.state.filtro}
                colCount={5}
                labelLocation={"top"}
                ref={(form) => (this._formInstance = form)}

              >
                <SimpleItem dataField="protocoloProtesto" colSpan={2} editorType={"dxTextBox"}
                  editorOptions={{ onKeyPress: this.onKeyPress, maxLength: 10 }} helpText={"N.º do Protocolo completo com zeros a Esquerda, ex.: 0099999999."}>
                  <Label text="Protocolo Distribuição" />
                </SimpleItem>
              </Form>
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ display: "inline-block" }}>
                  <Button
                    width={140}
                    text="Filtrar"
                    type="success"
                    onClick={this.filtrar}
                  />
                </div>
                <div style={{ display: "inline-block", marginLeft: "10px" }}>
                  <Button
                    width={160}
                    text="Nova Pesquisa"
                    onClick={this.limparFiltro}
                  />
                </div>
              </div>
            </form>
            <br />
            <br />
            <DataGrid
              ref={(grid) => (this._gridInstance = grid)}
              dataSource={this.state.cdaDs}
              showBorders={true}
              rowAlternationEnabled={true}
              remoteOperations={true}
              columnAutoWidth={true}
              noDataText={this.state._uiTextoGrid}
            >
              <Editing
                allowDeleting={false}
                allowUpdating={false}
                useIcons={true}
              />

              <Column
                type="buttons"
                fixed={true}
                fixedPosition={"left"}
                buttons={[
                  {
                    hint: "Imprimir CDA",
                    icon: "print",
                    onClick: (e) => this.printCda(e.row.data.id),
                  },
                  {
                    hint: "Imprimir DAM",
                    icon: "pdffile",
                    visible: (e)=> e.row.data.stDamDisponivel,
                    onClick: (e) =>
                      this.printDam(e.row.data.id, e),
                  },
                  // {
                  //   hint: "Atualizar DAM",
                  //   icon: "repeat",
                  //   onClick: (e) =>
                  //     this.setState({
                  //       cdaId: e.row.data.id,
                  //       popupVisible: true,
                  //     }),
                  // },
                ]}
              />
              <Column
                dataField="numero"
                caption={"Número"}
                width={100}
                alignment="center"
              />
              <Column
                dataField="ano"
                caption={"Exercício"}
                width={100}
                alignment="center"
              />
              <Column dataField="protocoloProtesto" caption={"Protocolo"} width={120} />
              <Column dataField="dataProtocoloProtesto" caption={"Dt. Protocolo"} dataType="date" width={110} />
              <Column dataField="valorProtestado" caption={"Vr. Protesto"} dataType="money" width={120} format={"R$ #,##0.00"} alignment={"right"} />
              <Column dataField="contribuinte.cpfCnpjFormatado" caption={"CPF/CNPJ"} width={160} />
              <Column dataField="contribuinte.nomeECodigo" caption={"Contribuinte"} />
            </DataGrid>
          </div>
        </div>
        <Popup
          visible={this.state.popupVisible}
          title="Atualizar data de vencimento"
          width={450}
          height={250}
          onHiding={(e) => this.setState({ popupVisible: false })}
        >
          <p>Deseja realmente atualizar o DAM?</p>
          <ToolbarItem
            toolbar={"bottom"}
            widget="dxButton"
            location="after"
            options={{
              text: "Não",
              width: 150,
              onClick: () => this.setState({ popupVisible: false }),
            }}
          />
          <ToolbarItem
            toolbar={"bottom"}
            widget="dxButton"
            location="after"
            options={{
              text: "Sim",
              type: "success",
              width: 150,
              onClick: () => this.updateDam(),
            }}
          />
        </Popup>
      </React.Fragment>
    );
  }

  async printCda(cdaId) {
    try {
      this.setState({ _uiMsgCarregando: "Imprimindo CDA..." });
      if (cdaId && this.state.prefeitura) {
        await showReport(`/cda/imprimir_cda/${this.state.prefeitura}/${cdaId}`);
      }
    }
    finally {
      this.setState({ _uiMsgCarregando: "" });
    }

  }

  async printDam(cdaId, e) {
    try {
      this.setState({ _uiMsgCarregando: "Gerando DAM..." });
      if (cdaId && this.state.prefeitura) {
        await showReport(
          `/cda/imprimir_dam/${this.state.prefeitura}/${cdaId}`
        );
      }
    }
    finally {
      this.setState({ _uiMsgCarregando: "" });
    }

  }

  async updateDam() {

    const { cdaId } = this.state;

    if (cdaId && this.state.prefeitura) {

      try {

        const response = await api.post(
          `/cda/atualizar_dam/${this.state.prefeitura}`,
          {
            cdaId
          }
        )

        if (response.data.sucesso) {
          showReportString(response.data.rptStr);
          await this.filtrar()
          // const cdaDs = this.fetchCdaData(this.state.prefeitura, this.state.protocoloProtesto);
          this.setState({ popupVisible: false });
        }
        else {
          notify(response.msgErro, "Error");
        }
      }
      catch (error) {
        console.log(error);
        notify(error, "error");
      }



    }
  }
}

export default CdaBox;
