import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import "../emitirCndGlobal.scss";
import Form, { SimpleItem, GroupItem, Label } from "devextreme-react/form";
import api from "../../../services/api";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import ambiente from "../../../services/ambiente";

const ConfirmarDadosImovel = ({ location }) => {
  const history = useHistory();
  const { prefeitura } = useParams();
  let dados = setDados();
  const [data] = useState(dados);

  function setDados() {
    if (location.props !== undefined) {
      return location.props.dados;
    } else {
      return {}
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.set("inscricao", data.imovel.inscricao);
      formData.set("cpfCnpj", data.contribuinte.cpfCnpj);
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      const response = await api.post(
        `cnd/imovel/emitir/${prefeitura}/`,
        formData,
        config
      );
      if (response.data.damEmitido) {
        const protocolo = await api.get(`protocolo/${prefeitura}/${response.data.protocolo}`);
        window.open(
          `${ambiente.URL_API_PORTAL}pdf/dam/${prefeitura}/${response.data.protocolo}`
        );
        history.replace({
          pathname: `/${prefeitura}/acompanhamento/`,
          props: protocolo.data,
        });
      } else if (response.data.certidaoEmitida) {
        let protocolo;
        if (response.data.externo) {
          protocolo = response.data.objProtocolo;
          protocolo = { ...protocolo, origem: 1, prefeituraId: response.data.prefeituraId };
        }
        else {
          const respProtocolo = await api.get(`protocolo/${prefeitura}/${response.data.protocolo}`);
          protocolo = respProtocolo.data;
          window.open(
            `${ambiente.URL_API_PORTAL}pdf/cnd/${prefeitura}/${response.data.protocolo}`
          );
        }
        history.replace({
          pathname: `/${prefeitura}/acompanhamento/`,
          props: protocolo,
        });
      } else {
        alert("Erro ao processar ação! Entre em contato com a prefeitura!");
      }
    } catch (err) { }
  }

  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"my-card responsive-paddings"}>
          <div className="input-box">
            <h2>Confirme seus dados</h2>
            <br></br>
            <form onSubmit={handleSubmit}>
              <Form formData={data.imovel} colCount={2}>
                <GroupItem
                  caption={"DADOS DO IMÓVEL"}
                  colSpan={2}
                  colCount={18}
                >
                  <SimpleItem
                    colSpan={2}
                    disabled={true}
                    dataField={"inscricao"}
                  >
                    <Label text="INSCRIÇÃO" />
                  </SimpleItem>
                  <SimpleItem
                    colSpan={4}
                    disabled={true}
                    dataField={"cartografia"}
                  >
                    <Label text="CARTOGRAFIA" />
                  </SimpleItem>
                  <SimpleItem
                    colSpan={3}
                    disabled={true}
                    dataField={"natureza"}
                  >
                    <Label text="NATUREZA" />
                  </SimpleItem>
                  <SimpleItem
                    colSpan={3}
                    disabled={true}
                    dataField={"codigoSegmento"}
                  >
                    <Label text="COD. SEGMENTO" />
                  </SimpleItem>
                  <SimpleItem
                    colSpan={3}
                    disabled={true}
                    dataField={"areaLote"}
                  >
                    <Label text="ÁREA LOTE(m²)" />
                  </SimpleItem>
                  <SimpleItem
                    colSpan={3}
                    disabled={true}
                    dataField={"areaEdificada"}
                  >
                    <Label text="ÁREA EDIFICADA(m²)" />
                  </SimpleItem>
                  <SimpleItem
                    colSpan={11}
                    disabled={true}
                    dataField={"endereco"}
                  >
                    <Label text="ENDEREÇO" />
                  </SimpleItem>
                  <SimpleItem colSpan={7} disabled={true} dataField={"bairro"}>
                    <Label text="BAIRRO" />
                  </SimpleItem>
                  <SimpleItem
                    colSpan={13}
                    disabled={true}
                    dataField={"complemento"}
                  >
                    <Label text="COMPLEMENTO" />
                  </SimpleItem>
                  <SimpleItem colSpan={5} disabled={true} dataField={"cep"}>
                    <Label text="CEP" />
                  </SimpleItem>
                </GroupItem>
              </Form>
              <Form formData={data.contribuinte} colCount={2}>
                <GroupItem
                  caption={"DADOS DO PROPRIETÁRIO"}
                  colSpan={2}
                  colCount={16}
                >
                  <SimpleItem colSpan={10} disabled={true} dataField={"nome"}>
                    <Label text="NOME/RAZÃO SOCIAL" />
                  </SimpleItem>
                  <SimpleItem
                    colSpan={2}
                    disabled={true}
                    dataField={"inscricao"}
                  >
                    <Label text="INSCRIÇÃO" />
                  </SimpleItem>
                  <SimpleItem colSpan={4} disabled={true} dataField={"cpfCnpj"}>
                    <Label text="CPF/CNPJ" />
                  </SimpleItem>
                  <SimpleItem
                    colSpan={10}
                    disabled={true}
                    dataField={"endereco"}
                  >
                    <Label text="ENDEREÇO" />
                  </SimpleItem>
                  <SimpleItem colSpan={6} disabled={true} dataField={"bairro"}>
                    <Label text="BAIRRO" />
                  </SimpleItem>
                  <SimpleItem
                    colSpan={8}
                    disabled={true}
                    dataField={"complemento"}
                  >
                    <Label text="COMPLEMENTO" />
                  </SimpleItem>
                  <SimpleItem
                    colSpan={4}
                    disabled={true}
                    dataField={"cidadeUF"}
                  >
                    <Label text="CIDADE" />
                  </SimpleItem>
                  <SimpleItem colSpan={4} disabled={true} dataField={"cep"}>
                    <Label text="CEP" />
                  </SimpleItem>
                  <SimpleItem isRequired colSpan={8} dataField={"email"}>
                    <Label text="E-MAIL" />
                  </SimpleItem>
                  <SimpleItem isRequired colSpan={4} dataField={"fone"}>
                    <Label text="TELEFONE" />
                  </SimpleItem>
                  <SimpleItem isRequired colSpan={4} dataField={"celular"}>
                    <Label text="CELULAR" />
                  </SimpleItem>
                </GroupItem>
              </Form>
              <br></br>
              <br></br>
              <div className="grouping-items">
                <button
                  className={"button"}
                  style={{ color: "white", backgroundColor: "grey" }}
                  onClick={() => {
                    history.replace(`/${prefeitura}/cnd/imovel/`);
                  }}
                >
                  <FaRegArrowAltCircleLeft /> Voltar
                </button>
                <button type="submit" className="button">
                  Confirmar e Emitir
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ConfirmarDadosImovel;
