import React, { useState, useEffect } from "react";
import "./index.scss";
import api from "../../services/apiSgtWeb";
import { Button, SelectBox } from "devextreme-react";
import Form, { SimpleItem, GroupItem, Label } from "devextreme-react/form";
import { useHistory } from "react-router-dom";
import notify from "devextreme/ui/notify";
import { FileUploader } from "devextreme-react";
import DataSource from "devextreme/data/data_source";

const Protocolo = () => {
  const municipioId = localStorage.getItem("municipioId");
  const [state, setState] = useState({ municipioId, avisoPrefeitura: "" });
  const [files, setFiles] = useState([]);
  const [tiposProtocolo, setTiposProtocolo] = useState([]);
  const tiposProtocoloDs = new DataSource({
    store: {
      data: tiposProtocolo,
      type: "array",
      key: "id",
      
    },
    
  });
  const [avisoPrefeitura, setAvisoPrefeitura] = useState("");
  const [tipoId, setTipoId] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    const fetchTiposProtocolo = async () => {
      try {
        const response = await api.get(
          `https://apisgtwebteste.trimapservicos.com.br/api/protocolos/tipos_atendimento/portal/${municipioId}`
        );
        if (response.status === 200 && response.data) {
          setTiposProtocolo(response.data.data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchTiposProtocolo();
  }, []);

  const tiposOptions = {
    editorType: "dxSelectBox",
    searchEnabled: true,
    dataSource: tiposProtocolo,
    valueExpr: "id",
    displayExpr: "nome",
    onValueChanged: (e) => handleAvisoPrefeituraChange(e),
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.set("values", JSON.stringify(state));
    formData.set("files", files);
    try {
      const response = await api.post(
        `https://apisgtwebteste.trimapservicos.com.br/api/protocolos/inserir`,
        formData
      );
      if (response.status === 200) {
        history.push("confirmado/" + response.data.cv);
      } else {
        notify(
          {
            message:
              "Ocorreu um erro ao cadastrar o protocolo. Entre em contato com o suporte!",
            closeOnClick: true,
            position: { at: "top center", my: "top center", offset: "0 50" },
          },
          "error",
          10000
        );
      }
    } catch (err) {
      notify(
        {
          message:
            "Ocorreu um erro ao cadastrar o protocolo. Entre em contato com o suporte!",
          closeOnClick: true,
          position: { at: "top center", my: "top center", offset: "0 50" },
        },
        "error",
        10000
      );
    }
  };

  const handleAvisoPrefeituraChange = async (e) => {
    //e.event.preventDefault();
    //setTipoId(e.value);
    const avisoPrefeitura = await tiposProtocoloDs.load();
    if (avisoPrefeitura) {
      let filter = avisoPrefeitura.filter((item) => (item.id = e.value));
      console.log(filter);
    }

    if (avisoPrefeitura) {
      setAvisoPrefeitura(avisoPrefeitura);
    }
  };

  return (
    <React.Fragment>
      <div className={"content-block dx-card responsive-paddings"}>
        <Form formData={state} colCount={2}>
          <GroupItem
            caption="INFORMAÇÕES DO PROTOCOLO"
            colSpan={2}
            colCount={2}
          >
            <SimpleItem colSpan={1} dataField={"nomeSolicitante"}>
              <Label text="NOME" />
            </SimpleItem>
            <SimpleItem colSpan={1} dataField={"documentoSolicitante"}>
              <Label text="CPF/CNPJ" />
            </SimpleItem>
            <SimpleItem
              colSpan={1}
              dataField={tipoId}
              editorType={"dxSelectBox"}
              editorOptions={tiposOptions}
            >
              <Label text="TIPO DE PROTOCOLO" />
            </SimpleItem>
            <SelectBox
              dataSource={tiposProtocoloDs}
              displayExpr="nome"
              valueExpr="id"
              onValueChanged={(e) => handleAvisoPrefeituraChange(e)}
            />
            <SimpleItem colSpan={1} dataField={"solicitacoesPrefeitura"}>
              <Label text="SOLICITAÇÃO" />
            </SimpleItem>
          </GroupItem>
          <GroupItem caption="ENDEREÇO" colSpan={2} colCount={4}>
            <SimpleItem colSpan={2} dataField={"logradouro"}>
              <Label text="LOGRADOURO" />
            </SimpleItem>
            <SimpleItem colSpan={1} dataField={"numero"}>
              <Label text="NÚMERO" />
            </SimpleItem>
            <SimpleItem colSpan={1} dataField={"bairro"}>
              <Label text="BAIRRO" />
            </SimpleItem>
            <SimpleItem colSpan={2} dataField={"complemento"}>
              <Label text="COMPLEMENTO" />
            </SimpleItem>
            <SimpleItem colSpan={1} dataField={"cep"}>
              <Label text="CEP" />
            </SimpleItem>
            <SimpleItem colSpan={1} dataField={"municipioId"}>
              <Label text="COD. IBGE" />
            </SimpleItem>
          </GroupItem>
        </Form>

        <div dangerouslySetInnerHTML={{ __html: avisoPrefeitura }} />

        <Form formData={state} colCount={2}>
          <GroupItem caption="DOCUMENTOS" colSpan={2} colCount={2}>
            <SimpleItem
              colSpan={2}
              dataField={"documentos"}
              editorType={"dxTextArea"}
              editorOptions={{ height: "120px" }}
            >
              <Label text="Documentos" />
            </SimpleItem>
          </GroupItem>
          <GroupItem caption="ANEXAR ARQUIVOS" colSpan={2} colCount={2}>
            <FileUploader
              onValueChanged={(e) => setFiles(e.value)}
              name={"myFile"}
              selectButtonText="Selecione o(s) arquivo(s)"
              labelText="ou arraste os arquivos aqui"
              accept={"*"}
              multiple={true}
              uploadMode={"useForm"}
            />
          </GroupItem>
        </Form>

        <div
          style={{
            display: "flex",
            marginTop: "25px",
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <div style={{ display: "inline-block" }}>
            <Button
              width={120}
              text="Confirmar"
              type="success"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Protocolo;
