import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
//import React, { Component, useState, useDebugValue } from 'react';
import React, { Component } from "react";
//import { Route, Switch, BrowserRouter, useParams, useLocation, useHistory, useRouteMatch, Redirect } from 'react-router-dom';
import { Switch, Redirect } from "react-router-dom";
//import { navigation, configureMenuItens, setMenuItens } from './app-navigation';
import { navigation } from "./app-navigation";
//import routes from './app-routes';
import "./App.scss";
import "./dx-styles.scss";
import { Footer, LoginForm } from "./components";
//import { SideNavOuterToolbar as SideNavBarLayout, SingleCard } from "./layouts";
import { SideNavOuterToolbar as SideNavBarLayout } from "./layouts";
import { sizes, subscribe, unsubscribe } from "./utils/media-query";
import axios from "axios";
//import { NotFoundPage } from "./pages/"
//import { SubvalueIndicator } from 'devextreme-react/circular-gauge';
//import { findAllInRenderedTree } from 'react-dom/test-utils';
//import { estaLogado, salvarUserData, removerUserData, obterUserInfo } from "./services/LoginService";
import { estaLogado, obterUserInfo } from "./services/LoginService";
//import api from './services/api';
//import { withRouter } from "react-router-dom";
import MyRoutes from "./MyRoutes";
//import LoginPage from "./components/login-form/login-page";

import ptMessages from "devextreme/localization/messages/pt.json";
import { locale, loadMessages } from "devextreme/localization";

//let apiLocal = "http://localhost:4040/api/prefeitura";
//let apiOnline = "http://45.32.167.221:4040/api/prefeitura";
let apiOnLine = "";

const apiOnLineDev = "http://localhost:4040/api/prefeitura";
const apiOnLineTestes = "http://45.35.104.141:4040/api/prefeitura";
const apiOnLineProd = "https://apiportal.devtrimap.com.br/api/prefeitura";

if (process.env.REACT_APP_ESTAGIO === "dev") {
  apiOnLine = apiOnLineDev;
} else if (process.env.REACT_APP_ESTAGIO === "testes") {
  apiOnLine = apiOnLineTestes;
} else if (process.env.REACT_APP_ESTAGIO === "prod") {
  apiOnLine = apiOnLineProd;
}

apiOnLine = apiOnLineProd;

//const LoginContainer = ({ logIn }) => <LoginForm onLoginClick={logIn} />;

// const NotAuthPage = (props) => (
//   <SingleCard>
//     <Route render={() => <LoginContainer {...props} />} />
//   </SingleCard>
// );

// const AuthPage = (props) => {

//   let prefeitura = "ipaporanga.ce";

//   if (!carregando && clientes.includes(prefeitura)) {

//     if (clientes && clientes.includes(prefeitura)) {
//       return (
//         <SideNavBarLayout menuItems={itens} title={prefeitura.toUpperCase().replace(/\./, "-")} {...props} visible={false} codigoPrefeitura={prefeitura} logIn={props.logIn} logOut={props.logOut}>
//           <Switch>
//             <Route exact path="/:prefeitura/*" render={(cProps) => <MyRoutes props={{ ...cProps }} logIn={props.logIn} logOut={props.logOut} clientes={clientes} rotas={rotas} />} />
//           </Switch>

//           <Footer>
//             Copyright © 2009-2020 <br />
//             Trimap Assessoria Tributária LTDA
//         </Footer>
//         </SideNavBarLayout>
//       );
//     }
//   }
// };

class App extends Component {
  constructor(props) {
    super(props);

    loadMessages(ptMessages);

    locale("pt-BR");

    const logado = estaLogado();
    const userInfo = obterUserInfo();
    const prefeitura = props.match.params.prefeitura;

    this.userMenuItems = [
      {
        text: "Sair",
        icon: "runner",
        onClick: this.logOut,
      },
    ];

    this.state = {
      carregando: true,
      logado,
      logando: false,
      userInfo,
      prefeitura,
      clientes: [],
      screenSizeClass: this.getScreenSizeClass(),
    };
  }

  async componentDidMount() {
    subscribe(this.screenSizeChanged);
    var clientes = await this.buscarClientes();
    this.menuItens = await navigation(this.state.prefeitura, this.state.logado, obterUserInfo());
    this.setState({ clientes, carregando: false });
  }

  componentWillUnmount() {
    unsubscribe(this.screenSizeChanged);
  }

  buscarClientes = async () => {
    try {
      let resp = await axios.get(apiOnLine);
      return resp.data;
    } catch (error) {
      alert(error);
    }

    return [];
  };

  get logado() {
    return this.loginService.logado();
  }

  render() {
    // const { loggedIn, usuario } = this.state;
    //const { clientes, rotas, carregando, logado, userData } = this.state;
    const { clientes, rotas, carregando } = this.state;

    let itens = [];
    let prefeitura = this.props.match.params.prefeitura;

    if (carregando) {
      return <h3>Carregando</h3>;
    }

    if (!clientes.map(x => x.codigoPortal).includes(prefeitura)) {
      return <Redirect to="/" />;
    }

    {
      /* {this.state.logando ? <LoginPage loginRealizado={this.loginRealizado} /> : < MyRoutes logado={this.state.logado} />} */
    }

    let tituloTopo = prefeitura.toUpperCase().replace(/\./, "-");

    if (prefeitura == "deputadoirapuanpinheiro.ce") {
      tituloTopo = "DEP. IRAPUAN PINHEIRO - CE"
    }
    else if (prefeitura == "novooriente.ce") {
      tituloTopo = "NOVO ORIENTE - CE";
    }

    return (
      <div className={`app ${this.state.screenSizeClass}`}>
        {/* <BrowserRouter>{loggedIn ? <AuthPage userMenuItems={this.userMenuItems} logado={loggedIn} /> : <NotAuthPage logIn={this.logIn} />}</BrowserRouter> */}

        <SideNavBarLayout
          menuItems={this.menuItens}
          title={tituloTopo}
          {...this.props}
          visible={false}
          codigoPrefeitura={prefeitura}
          logIn={this.logIn}
          logOut={this.logOut}
          loginRealizado={this.loginRealizado}
        >
          <Switch>
            <MyRoutes
              logIn={this.logIn}
              logOut={this.logOut}
              clientes={clientes}
              rotas={rotas}
              loginRealizado={this.loginRealizado}
            />
          </Switch>

          <Footer>
            Copyright © 2009-2020 <br />
            Trimap Assessoria Tributária LTDA
          </Footer>
        </SideNavBarLayout>
      </div>
    );
  }

  getScreenSizeClass() {
    const screenSizes = sizes();
    return Object.keys(screenSizes)
      .filter((cl) => screenSizes[cl])
      .join(" ");
  }

  screenSizeChanged = () => {
    this.setState({
      screenSizeClass: this.getScreenSizeClass(),
    });
  };

  // logIn = (prefeitura, login, senha, frmLogin) => {
  logIn = (userData) => {
    // this.setState({ logado: true, userData: userData });
    this.setState({ logando: true });
  };

  logOut = () => {
    window.location.reload();
  };

  loginRealizado = () => {
    window.location.reload();
  };

  salvarCliente(clientes) {
    this.setState({ clientes });
  }
}

export default App;
