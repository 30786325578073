import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import "../emitirCndGlobal.scss";
import Form, { SimpleItem, GroupItem, Label } from "devextreme-react/form";
import api from "../../../services/api";
import notify from 'devextreme/ui/notify';
import captchaKey from '../../../tests/captchakey';

const EmitirCndEconomico = () => {
  const [data, setData] = useState({
    inscricao: "",
    cpfCnpj: "",
    permission: false,
  });
  const history = useHistory();
  const { prefeitura } = useParams();

  function handleReCaptchaSize() {
    let smallSize = window.matchMedia("(max-width: 900px)");
    if (smallSize.matches) {
      return "compact";
    } else {
      return "normal";
    }
  }

  function updateData() {
    //Começo:
    //Esse trecho de programa serve para tratar espaços extras colocados pelo usuário. Medida de segurança.
    //
    let doc = data.cpfCnpj;
    let subs = data.inscricao;
    subs = subs.replace(/ /g, "");
    doc = doc
      .replace(/ /g, "")
      .replace(/\./, "")
      .replace(/-/, "")
      .replace(/\//, "");

    setData({ ...data, inscricao: subs, cpfCnpj: doc });
    //
    //Fim da verificação.
  }

  function onChange(e) {
    setData({ ...data, permission: true });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    updateData();
    if (data.permission !== false) {
      try {
        const formData = new FormData();
        formData.set("cpfCnpj", data.cpfCnpj);
        formData.set("inscricao", data.inscricao);
        let config =  {headers: {'Content-Type': 'multipart/form-data'}}
        const response = await api.post(
          `cnd/economico/validar/${prefeitura}/`, formData, config
        );
        if (response.status===200 && response.data.autorizado)  {
          history.push({
            pathname: "confirmar",
            props: response.data,
          });
        } else if(response.status===200 && !response.data.autorizado) {
          history.replace({
            pathname: `/${prefeitura}/nao-autorizado`,
            props: response.data,
          });
        } else {
          alert("Não foi possível realizar a solicitação.");
        }
      } catch (err) {
        notify({message:"Os dados utilizados são inválidos. Tente novamente.", closeOnClick:true, position:{ at: 'top center', my: 'top center', offset: '0 50'}}, "error", 100000);
      }
    } else alert("É necessário responder o ReCAPTCHA");
  }

  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"my-card responsive-paddings"}>
          <div className="input-box">
            <h2>Certidão da Empresa</h2>
            <form onSubmit={handleSubmit}>
              <Form formData={data} colCount={1} labelLocation={"top"}>
                <GroupItem>
                  <SimpleItem dataField={"inscricao"}>
                    <Label text="Inscrição econômica" />
                  </SimpleItem>
                  <SimpleItem dataField={"cpfCnpj"}>
                    <Label text="CPF ou CNPJ (Somente números)" />
                  </SimpleItem>
                </GroupItem>
              </Form>
              <ReCAPTCHA
                className="captcha"
                hl="pt-BR"
                sitekey={captchaKey.key}
                onChange={onChange}
                size={handleReCaptchaSize()}
                onExpired={()=>setData({...data, permission:false})}
              />
              <button type="submit" className="button">
                Realizar Consulta
              </button>
            </form>
            <p>( * ) Obrigatório Inscrição e/ou CPF/CNPJ</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmitirCndEconomico;
