import React, { useState } from "react";
import "./index.scss";
import Form, { SimpleItem, GroupItem, Label } from "devextreme-react/form";
import { useParams } from "react-router-dom";
import notify from "devextreme/ui/notify";
import ReCAPTCHA from "react-google-recaptcha";
import captchaKey from "../../tests/captchakey";
import showReport from "../../showReport";

const ImprimirFichaCpbs = () => {
  const [state] = useState({});
  const [captcha, setCaptcha] = useState({
    permission: false,
  });
  const { prefeitura } = useParams();

  function handleReCaptchaSize() {
    let smallSize = window.matchMedia("(max-width: 900px)");
    if (smallSize.matches) {
      return "compact";
    } else {
      return "normal";
    }
  }

  function onChange(e) {
    setCaptcha({ ...captcha, permission: true });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const { cnpj, inscricaoMunicipal } = state;
    if (captcha.permission) {
      try {
        showReport(
          `report/portal/ficha_cpbs/${prefeitura}/${cnpj}/${inscricaoMunicipal}`
        );
      } catch (err) {
        notify(
          {
            message:
              "Código de Validação Inválido. Verifique-o e tente novamente!",
            closeOnClick: true,
            position: { at: "top center", my: "top center", offset: "0 50" },
          },
          "error",
          10000
        );
      }
    } else {
      alert("É necessário responder ao ReCaptcha!");
    }
  }
  return (
    <React.Fragment>
      <div className={"content-block dx-card responsive-paddings"}>
        <div className="card-content">
          <form onSubmit={(event) => handleSubmit(event)}>
            <Form formData={state} colCount={1} labelLocation={"top"}>
              <GroupItem>
                <SimpleItem dataField={"cnpj"}>
                  <Label text="CNPJ" />
                </SimpleItem>
                <SimpleItem dataField={"inscricaoMunicipal"}>
                  <Label text="Inscrição Municipal" />
                </SimpleItem>
              </GroupItem>
            </Form>

            <div>
              <ReCAPTCHA
                className="captcha"
                hl="pt-BR"
                sitekey={captchaKey.key}
                onChange={onChange}
                onExpired={() =>
                  setCaptcha({
                    ...captcha,
                    permission: false,
                  })
                }
                size={handleReCaptchaSize()}
              />
            </div>

            <button
              type="submit"
              className="button"
              disabled={captcha.permission ? false : true}
            >
              Imprimir
            </button>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ImprimirFichaCpbs;
