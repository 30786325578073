import React from 'react';
import TextBox from 'devextreme-react/text-box';
import ValidationGroup from 'devextreme-react/validation-group';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import Button from 'devextreme-react/button';
//import CheckBox from 'devextreme-react/check-box';
import './login-form.scss';
import appInfo from '../../app-info';
//import { Link, withRouter } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
//import { salvarUserData, estaLogado } from "./../../services/LoginService";
import { salvarUserData } from "./../../services/LoginService";
import api from "./../../services/api";
//import { Redirect } from "react-router-dom";
import notify from "devextreme/ui/notify"

const LOGIN_STATUS_NENHUM = 1;
//const LOGIN_STATUS_LOGANDO = 2;
const LOGIN_STATUS_LOGADO = 3;

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login: '',
      password: '',
      loginStatus : LOGIN_STATUS_NENHUM
    };

    

  }



  render() {
    //const { login, password, loginStatus } = this.state;
    const { login, password } = this.state;

    return (
      <ValidationGroup>
        <div className={'login-header'}>
          <div className={'title'}>{appInfo.title}</div>
          <div>Faça Login com sua conta</div>
        </div>
        <div className={'dx-field'}>
          <TextBox
            value={login}
            onValueChanged={this.loginChanged}
            placeholder={'Login'}
            width={'100%'}
          >
            <Validator>
              <RequiredRule message={'Login é obrigatório'} />
            </Validator>
          </TextBox>
        </div>
        <div className={'dx-field'}>
          <TextBox
            mode={'password'}
            value={password}
            onValueChanged={this.passwordChanged}
            placeholder={'Senha'}
            width={'100%'}
          >
            <Validator>
              <RequiredRule message={'Senha is required'} />
            </Validator>
          </TextBox>
        </div>
        {/* <div className={'dx-field'}>
          <CheckBox defaultValue={false} text={'Manter-me logado'} />
        </div> */}
        <div className={'dx-field'}>
          <Button
            type={'default'}
            text={this.state.logando ? "Entrando..." : "Entrar"}
            onClick={this.onLoginClick}
            width={'100%'} disabled={this.state.logando}
          />
        </div>
        {/* <div className={'dx-field'}>
          <Link to={'/recovery'} onClick={e => e.preventDefault()}>Forgot password ?</Link>
        </div>
        <div className={'dx-field'}>
          <Button type={'normal'} text={'Create an account'} width={'100%'} />
        </div> */}
      </ValidationGroup>
    );
  }

  loginChanged = e => {
    this.setState({ login: e.value });
  };

  passwordChanged = e => {
    this.setState({ password: e.value });
  };

  onLoginClick = async args => {


    if (!args.validationGroup.validate().isValid) {
      return;
    }

    this.setState({ loginStatus: LOGIN_STATUS_LOGADO });

    const { login, password } = this.state;
    const prefeitura = this.props.match.params.prefeitura;

    try {
      var resp = await api.post(`login/${prefeitura}`, { login, senha: password });
      salvarUserData(resp.data);
      args.validationGroup.reset();
      notify("Login realizado com sucesso.", "success");
      this.props.props.loginRealizado();
    }
    catch (error) {
      console.log(error);
      notify("Falha ao logar, verifique os dados!", "error");
      this.setState({ loginStatus: LOGIN_STATUS_NENHUM });
    }


  };
}

export default withRouter(LoginForm);