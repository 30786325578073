import { createStore } from "devextreme-aspnet-data-nojquery";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";

import apiPortal from "./api";

const URL_TESTES = "https://apisgtwebteste.trimapservicos.com.br/api";

const API_BASE_URL = URL_TESTES;
const apiInfo = {
  API_URL: {
    CPBS: `${API_BASE_URL}/cpbs`,
    CONTRIBUINTE: `${API_BASE_URL}/contribuintes`,
    IMOVEL: `${API_BASE_URL}/imoveis/`,
    CND: `${API_BASE_URL}/cnds/`,
  },
};

function onBeforeSend(_, settings) {
  const token = "@portal-contribuinte-trimap-Token";
  settings.headers = {
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${token}`,
  };
}

function inserirOuAtualizar(dataSource, objOuId, obj) {
  let id = objOuId;
  let entidade = obj;
  if (!obj) {
    id = objOuId.id;
    entidade = objOuId;
  }

  if (id === undefined || id === 0) {
    return dataSource.insert(entidade);
  }

  return dataSource.update(id, entidade);
}

const criarCndDsVazia = function () {
  return new DataSource({
    store: new ArrayStore({
      key: "id",
      data: []
    })
  });
}

const criarCndDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.CND + "22",
    insertUrl: apiInfo.API_URL.CND,
    updateUrl: apiInfo.API_URL.CND,
    deleteUrl: apiInfo.API_URL.CND,
    onBeforeSend,
  });
};

const criarCndPortalDs = function (prefeitura) {
  return createStore({
    key: "id",
    loadUrl: apiPortal.defaults.baseURL + `cnd/${prefeitura}`,
    insertUrl: apiPortal.defaults.baseURL,
    updateUrl: apiPortal.defaults.baseURL,
    deleteUrl: apiPortal.defaults.baseURL,
    onBeforeSend,
  });
};

const criarCdaPortalDs = function (prefeitura) {
  return createStore({
    key: "id",
    loadUrl: apiPortal.API_URL.CDA + prefeitura,
    onBeforeSend,
  });
};

export { inserirOuAtualizar, criarCndDs, criarCndDsVazia, criarCndPortalDs, criarCdaPortalDs };
