import React, { useState } from "react";
import "./index.scss";
import { useHistory } from "react-router-dom";
import { FaFileDownload, FaRegArrowAltCircleLeft } from "react-icons/fa";
// import DataGrid, { Column, Pager, Paging } from "devextreme-react/data-grid";
import showReport from "../../showReport";
import { Form } from "devextreme-react";
import { Label, SimpleItem, GroupItem } from "devextreme-react/form";
import { LoadIndicator } from "devextreme-react/load-indicator";
import notify from "devextreme/ui/notify";
import ShowError from "../../AppServices";
import uiInfo from "../../ui-info";

const ResultadoValidacao = ({ location }) => {
  const history = useHistory();
  let dados = setDados();
  const [data] = useState(dados ?? []);
  const [validationItems] = useState(data.itens ?? []);
  const [disabledButton, setDisabledButton] = useState(false);

  function setDados() {
    if (location.props !== undefined) {
      return location.props;
    } else {
      notify(
        {
          message: "Validação não encontrada. Verifique-a e tente novamente!",
          closeOnClick: true,
          position: { at: "top center", my: "top center", offset: "0 50" },
        },
        "error",
        10000
      );
      history.goBack();
    }
  }

  function returnValidationItems(itemsValidation = []) {
    const items = [];
    itemsValidation.forEach((item) => {
      if (item.tipoDado === "decimal") {
        items.push(
          <SimpleItem
            key={item.id}
            editorType={"dxNumberBox"}
            editorOptions={{
              format: uiInfo.formatoNumerico.n2,
              value: item.valorDecimal,
            }}
            cssClass={"txtNumerico"}
          >
            <Label text={item.campoDisplay} />
          </SimpleItem>
        );
      } else if (item.tipoDado === "DateTime") {
        items.push(
          <SimpleItem
            key={item.id}
            editorType="dxDateBox"
            editorOptions={{
              displayFormat: "dd/MM/yyyy",
              width: "100%",
              useMaskBehavior: true,
              value: item.valorData,
            }}
          >
            <Label text={item.campoDisplay} />
          </SimpleItem>
        );
      } else {
        items.push(
          <SimpleItem
            key={item.id}
            editorType={"dxTextBox"}
            editorOptions={{
              value: item.valorRetorno,
            }}
          >
            <Label text={item.campoDisplay} />
          </SimpleItem>
        );
      }
    });
    return items;
  }

  function exibeDados() {
    return (
      <div>
        <Form id="frmPesquisa" formData={data} readOnly={true}>
          <GroupItem caption={"Dados da Validação"}>
            <SimpleItem
              dataField="dataValidacao"
              editorType="dxDateBox"
              editorOptions={{
                displayFormat: "dd/MM/yyyy",
                width: "100%",
                useMaskBehavior: true,
              }}
            >
              <Label text={"Data da Validação"} />
            </SimpleItem>
            <SimpleItem dataField="contribuinte.nome">
              <Label text={"Nome do Contribuinte"} />
            </SimpleItem>
            <SimpleItem dataField="contribuinte.cpfCnpjFormatado">
              <Label text={"Documento do Contribuinte"} />
            </SimpleItem>
            <SimpleItem dataField="resultadoTxt">
              <Label text={"Status"} />
            </SimpleItem>
            <SimpleItem dataField="resultadoValidacao">
              <Label text={"Resultado da Validação"} />
            </SimpleItem>
          </GroupItem>
          <GroupItem caption={"Itens da Validação"}>
            {returnValidationItems(validationItems)}
          </GroupItem>
        </Form>
      </div>
    );
  }

  async function handleReportPrint(event, printType) {
    event.preventDefault();
    setDisabledButton(true);
    showReport(
      `/validacao_documentos/imprimir_comprovante/${data.id}/${printType}`
    ).finally(() => {
      setDisabledButton(false);
    });
  }

  function renderButtonIcon(disabled) {
    if (disabled) {
      return (
        <LoadIndicator height={15} width={15} style={{ marginRight: "10px" }} />
      );
    }
    return <FaFileDownload size={15} style={{ marginRight: "10px" }} />;
  }

  return (
    <React.Fragment>
      <div className={"content-block dx-card responsive-paddings"}>
        <div className="card-content">
          <div className="protocol-content">
            <div className="dados">
              {exibeDados()}
              <div className="buttons">
                <button
                  className={"download-button"}
                  onClick={(event) => handleReportPrint(event, 1)}
                  disabled={disabledButton}
                >
                  {renderButtonIcon(disabledButton)}
                  Imprimir Comprovante
                </button>
                <button
                  className={"download-button"}
                  onClick={(event) => handleReportPrint(event, 3)}
                  disabled={disabledButton}
                >
                  {renderButtonIcon(disabledButton)}
                  Imprimir Documento
                </button>
                <button
                  className={"download-button"}
                  onClick={(event) => handleReportPrint(event, 2)}
                  disabled={disabledButton}
                >
                  {renderButtonIcon(disabledButton)}
                  Imprimir Ambos
                </button>
              </div>
              <button
                className={"button"}
                style={{ color: "white", backgroundColor: "grey" }}
                onClick={() => {
                  history.goBack();
                }}
              >
                <FaRegArrowAltCircleLeft /> Voltar
              </button>
            </div>
            <br></br>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResultadoValidacao;
