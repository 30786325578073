import React, { useState, useEffect } from "react";
import "./index.scss";
import { Button } from "devextreme-react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import api from "../../services/apiSgtWeb";
import notify from "devextreme/ui/notify";

const ProtocoloRecebido = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const municipioId = localStorage.getItem("municipioId");
  const [protocolo, setProtocolo] = useState({
    cv: undefined,
    status: undefined,
    aguardandoPagamento: false,
    tipoProtocolo: undefined,
    nomeSolicitante: undefined,
    municipio: undefined,
  });
  const history = useHistory();

  useEffect(() => {
    if (!state) {
      const fetchProtocolo = async () => {
        try {
          const response = await api.get(
            `https://apisgtwebteste.trimapservicos.com.br/api/protocolos/portal/buscar_protocolo/${municipioId}/${id}`
          );
          if (response.status === 200 && response.data) {
            setProtocolo(response.data);
          }
        } catch (err) {
          console.log(err.response);
          notify(
            {
              message: "Não foi possível encontrar o protocolo",
              closeOnClick: true,
              position: { at: "top center", my: "top center", offset: "0 50" },
            },
            "error",
            10000
          );
          history.goBack();
          window.location.reload();
        }
      };

      fetchProtocolo();
    } else {
      setProtocolo(state.data);
    }
  }, []);

  return (
    <React.Fragment>
      <div className={"content-block dx-card responsive-paddings"}>
        <div style={{ display: "inline-block" }}>
          <Button
            width={200}
            text="Voltar"
            type="default"
            onClick={() => history.goBack()}
          />
        </div>
        <div className={"protocol-body"}>
          <div className={"protocol-content"}>
            <p style={{ fontWeight: "bolder", fontSize: 22 }}>
              Código do protocolo: {protocolo.cv}
            </p>
            <p>Status: {protocolo.status}</p>
            <p>Solicitante: {protocolo.nomeSolicitante}</p>
            <p>Município: {protocolo.municipio}</p>
            <p>
              {protocolo.aguardandoPagamento ? "Aguardando Pagamento" : null}
            </p>
          </div>
          <p
            style={{
              fontSize: "18px",
              color: "#e32636",
              fontWeight: "bold",
              display: !state ? "" : "none",
            }}
          >
            Anote o código do protocolo
          </p>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "25px",
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
            alignItems: "center",
            gap: "25px",
          }}
        >
          <div style={{ display: "inline-block" }}>
            <Button
              width={200}
              text="Imprimir Protocolo"
              type="success"
              onClick={() => {}}
            />
          </div>
          <div style={{ display: "inline-block" }}>
            <Button
              width={200}
              text="Imprimir DAM"
              type="success"
              onClick={() => {}}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProtocoloRecebido;
